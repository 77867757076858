@media print {
    #invoice {
        width: 100%; /* Takes full width of the print area */
        padding-left : 10%;  /* Add margin to the right */
        padding: 0;  /* Remove any automatic padding */
    }









































    
    .pabido img {
        width: 15px;
        height: 15px;
        margin: 0 8px 16px 8px; /* Adjust margins to avoid excess spacing */
    }
}


.pabido {
    list-style-type: none;
    display: flex;
    align-items: flex-start;
}

.pabido img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 6px;
}

.pabido span {
    line-height: 1.5;
    /* Ensures proper spacing between lines of text */
}

.invoiceA {
    max-width: 800px;
    margin: 20px auto;
    background: #fff;
    padding-top: 0px;
    padding-bottom: 5px;
    border-right: 20px;
    border-left: 20px;
}

.invoice-header {
    padding-bottom: 10px;
}

.bill-to,
.invoice-details,
.invoice-summary {
    background-color: #f9f9f9;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    border-left: 5px solid #007bff;
}

.bill-to p,
.invoice-summary p {
    margin: 5px 0;
    font-size: 14px;
    line-height: 1.5;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th,
td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
    /* Ensure content starts from the top */
}

th {
    background-color: #e9ecef;
    color: #333;
}

.invoice-footer {
    background-color: #f2f2f2;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
}

.invoice-footer div {
    margin: 0;
    font-size: 14px;
}

.button-downloadF {
    border: none;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: block;
    width: 200px;
    margin: 20px auto;
}


* {
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
th,
td {
    color: #333;
}


.palambido {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.palambido {
    display: grid;
    gap: 10px;
    margin: 10px 0;
    grid-template-columns: 1fr;
}

.samirainfoItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
}

.label {
    font-weight: bold;
    color: #072C7B;
    margin-right: 10px;
    flex: 0 0 100px;
    /* Ensures the label takes a fixed width */
}

.value {
    color: #333;
    font-weight: normal;
    border: 1px solid #0056B3;
    padding: 5px;
    flex: 1;
    text-align: left;
    border-radius: 5px;
}

@media (min-width: 768px) {
    .palambido {
        grid-template-columns: 1fr 1fr;
    }
}


.left {
    text-align: left;
    margin: 0;
}

.right {
    text-align: right;
    margin: 0;
}

.mayadin {
    color: #072C7B;
}



.righto {
    text-align: right;
    margin: 0;
    color: #072C7B;
    font-weight: bold;
}


.kesredback p {
    color: red;
}

.kesredback {
    background-color: #F4F6FA;

}

.grifcellule {
    background-color: #faf4f4;
    height: 50px;
}

.rightoR {
    border: 1px solid #0056B3;
    text-align: right;
    margin: 0;
    color: #333;
    font-weight: normal;
    font-size: small;
    padding-bottom: 10px;
}

.det_graut {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 20px 0;
    text-align: start;
}
.responsive-divider {
    display: none; /* Hidden by default */
}

/* Display only on mobile devices */
@media (max-width: 768px) {
    .responsive-divider {
        display: block; /* Makes the element visible on mobile */
        height: 30px; /* Sets the height on mobile */
        background-color: transparent; /* Optional: add color if needed */
    }
}


@media (max-width: 768px) { /* Targets screens 768px wide or smaller */
    .det_graut {
        grid-template-columns: 1fr; /* Single column layout for mobile */
        text-align: start;
    }
}
@media only screen and (max-width: 768px) {
    .invoice {
        padding: 10px;
        margin: 10px;
    }

    .invoice-header {
        align-items: flex-start;
        text-align: center;
    }

    .bill-to,
    .invoice-details,
    .invoice-summary {
        padding: 10px;
        margin-top: 5px;
        border-left: none;
        border-top: 5px solid #007bff;
    }

    table,
    th,
    td {
        font-size: 12px;
    }

    th,
    td {
        padding: 4px;
    }

    .invoice-footer {
        padding: 10px;
        text-align: center;
        flex-direction: column;
    }

    .invoice-footer div {
        margin-bottom: 10px;
    }

    .button-downloadF {
        width: 100%;
        padding: 10px;
    }
}

.headerpdf {
    color: #072C7B;
    font-size: small;
}

.headerpdfT {
    font-size: medium;
    color: #072C7B;
    font-weight: bold;
}

@media (max-width: 768px) {
    .headerpdf {
        color: #072C7B;
        font-size: x-small;
    }

    .headerpdfT {
        font-size: medium;
        color: #072C7B;
        font-weight: bold;
    }
}























































































/* Additional responsive styles for the table */
.firstTable {
    width: 100%;
    overflow-x: auto;
}

.firstTable table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.firstTable th {
    background-color: #e9ecef;
    color: #333;
}

.firstTable img {
    width: 30px;
    /* Adjust width as needed */
    height: auto;
    /* Maintain aspect ratio */
}

.firstTable th,
.firstTable td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
    /* Ensure content starts from the top */
}

.circle-list-item {
    list-style-type: none;
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.circle-list-item img.icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

@media (max-width: 768px) {

    .firstTable table,
    .firstTable thead,
    .firstTable tbody,
    .firstTable th,
    .firstTable td,
    .firstTable tr {
        display: block;
    }

    .firstTable th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .firstTable tr {
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

    .firstTable td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        text-align: right;
    }

    .firstTable td:before {
        position: absolute;
        top: 50%;
        left: 10px;
        width: calc(50% - 20px);
        white-space: nowrap;
        transform: translateY(-50%);
        font-weight: bold;
        text-align: left;
    }

    .firstTable td:nth-of-type(1):before {
        content: "خدمات العيادات الخارجية";
    }

    .firstTable td:nth-of-type(2):before {
        content: "خدمات الولادة";
    }

    .firstTable td:nth-of-type(3):before {
        content: "درجة الإقامة";
    }

    .firstTable ul {
        padding: 0;
        list-style: none;
    }

    .firstTable li {
        padding: 5px 0;
        display: flex;
        align-items: center;
    }

    .firstTable img {
        margin-right: 10px;
    }
}


.fa-spinner {
    margin-right: 8px;
}

.kadah1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kadah2 {
    margin-top: 20px;
}

.kadah3 {
    text-align: center;
}

.kadah-text {
    font-size: 1.2em;
    color: #ffffff;
    background-color: #072C7B;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: 0 auto;
}

@media (max-width: 767px) {
    .kadah-text {
        font-size: 1em;
        padding: 8px 16px;
        max-width: 90%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .kadah-text {
        font-size: 1.1em;
        padding: 10px 18px;
        max-width: 80%;
    }
}

@media (min-width: 1024px) {
    .kadah-text {
        font-size: 1.2em;
        padding: 12px 24px;
        max-width: 600px;
    }
}











.red {
    background-color: red;
}




























.auth-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-top: 20px;
}

.form-label {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.form-input {
    font-size: 14px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.copy-button,
.submit-button,
.pdf-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.copy-button:hover,
.submit-button:hover,
.pdf-button:hover {
    background-color: #0056b3;
}

.pdf-button {
    background-color: #28a745;
}

.pdf-button:hover {
    background-color: #218838;
}

























.auth-container {

    max-width: 800px;
    margin: 0 auto;



    border: 1px solid #ddd;
    font-family: Arial, sans-serif;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}






@media (max-width: 768px) {
    .auth-container {
        padding: 0; /* Set padding to 0 for mobile */
    }
}
.auth-header {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
}

.invoice-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    border-bottom: 2px solid #000;
}

.headerpdfoT,
.headerpdfo {
    font-size: 14px;
    margin: 2px 0;
}

.headerpdfoT {
    font-weight: bold;
}

.auth-info {
    font-size: 16px;
    margin: 10px 0;
}

.error-message {
    color: red;
    font-size: 14px;
    margin: 10px 0;
}























.signature-section {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
}

.input-wrapper, .date-display,  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signature-pad {
    border: 1px solid #353434;
}

.input-wrapper input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 200px;
    text-align: center;
}

.button-group button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.button-group button:first-child {
    background-color: #dc3545;
    color: #fff;
    margin-bottom: 10px;
}

.button-group button:last-child {
    background-color: #007bff;
    color: #fff;
}

@media (max-width: 768px) {
    .signature-section {
        flex-direction: column;
        align-items: stretch;
    }

    /* Align name input and signature pad in a single row on smaller screens */
    .input-wrapper, .signature-pad {
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 100%;
        justify-content: center;
    }

    .input-wrapper input, .signature-pad canvas {
        width: auto;
        flex: 1;
    }
}














.footerLettre {
    display: flex;
    justify-content: center;
    gap: 30px; 
    flex-direction: row;
}

.signature-iconsa {
    width: 350px;             
    height: auto;           
    margin: 20px 0;           
    filter: grayscale(100%);   
    opacity: 0.8;              
    transition: transform 0.3s ease, opacity 0.3s ease;
}

/* .signature-iconsa:hover {
    transform: scale(1.1);    
    opacity: 1;              
} */
@media screen and (max-width: 768px) {
    .footerLettre {
        flex-direction: column; /* Change to column layout on small screens */
        align-items: center; /* Center align images vertically */
    }
    .signature-iconsa {
        width: 250px; /* Increased width for mobile */
    }
    .ferkaSebaa{
        padding-top: 0px;
    }
}




.ferkaSebaa{
    padding-top: 60px;
}