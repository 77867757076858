@media print {
    #invoice {
        width: 1200px;
    }


    .totire img {
        width: 15px;
        height: 15px;
        margin-right: 8px;
        margin-left: 8px;
        margin-top: 16px;
    }




}

.totire {
    list-style-type: none;
    display: flex;
    align-items: flex-start;
}

.totire img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 6px;
}

.totire span {
    line-height: 1.5;
    /* Ensures proper spacing between lines of text */
}

.invoice {
    max-width: 800px;
    margin: 20px auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.invoice-header {
    padding-bottom: 10px;
}

.bill-to,
.invoice-details,
.invoice-summary {
    background-color: #f9f9f9;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    border-left: 5px solid #007bff;
}

.bill-to p,
.invoice-summary p {
    margin: 5px 0;
    font-size: 14px;
    line-height: 1.5;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th,
td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
    /* Ensure content starts from the top */
}

th {
    background-color: #e9ecef;
    color: #333;
}

.invoice-footer {
    background-color: #f2f2f2;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
}

.invoice-footer div {
    margin: 0;
    font-size: 14px;
}

.button-download {
    border: 1px solid #f64141;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    display: block;
    width: 200px;
    margin: 20px auto;
    background-color: rgba(255, 0, 0, 0);
    color: white;
}

.SGBUtton {
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0) !important;
    color: white !important;
    border: 1px solid #0056B3;
    border-radius: 0%;
    width: 190px;
}

.containerhkina {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

  

* {
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
th,
td {
    color: #333;
}

.earope1 {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.earope2 {
    background-color: #f8f9fa;
    padding: 1rem;
    text-align: center;
}

.earope3 {
    flex: 1;
    padding: 1rem;
    background-color: #e9ecef;
}

.redkhatb {
    color: red;
    font-weight: normal;
    font-size: 14px;
    /* Example font size */
}

.earope4 {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.earope5 {
    background-color: #f8f9fa;
    padding: 1rem;
    text-align: center;
}

.infoBlock {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.infoBlock {
    display: grid;
    gap: 10px;
    margin: 10px 0;
    grid-template-columns: 1fr;
}

.infoItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(249, 249, 249, 0);
    padding: 10px;
    border-radius: 5px;
}

.label {
    font-weight: normal !important;
    color: #000000 !important;
    margin-right: 10px;
    flex: 0 0 100px;
    /* Ensures the label takes a fixed width */
}

.value {
    color: #333;
    font-weight: normal;
    border: 1px solid #0056B3;
    padding: 5px;
    flex: 1;
    text-align: left;
    border-radius: 5px;
}

@media (min-width: 768px) {
    .infoBlock {
        grid-template-columns: 1fr 1fr;
    }
}


.left {
    text-align: left;
    margin: 0;
}

.right {
    text-align: right;
    margin: 0;
}

.mayadin {
    color: #072C7B;
}

.lefto {
    text-align: left;
    margin: 0;
    color: #072C7B;
    font-weight: bold;
}

.righto {
    text-align: right;
    margin: 0;
    color: #072C7B;
    font-weight: bold;
}

.leftoR {
    border: 1px solid #0056B3;
    text-align: left;
    margin: 0;
    color: #333;
    font-weight: normal;
    font-size: small;
    padding-bottom: 10px;
}

.redback p {
    color: red;
}

.redback {
    background-color: #F4F6FA;
    height: 70px;
}

.rightoR {
    border: 1px solid #0056B3;
    text-align: right;
    margin: 0;
    color: #333;
    font-weight: normal;
    font-size: small;
    padding-bottom: 10px;
}

@media only screen and (max-width: 768px) {
    .invoice {
        padding: 10px;
        margin: 10px;
    }

    .invoice-header {
        align-items: flex-start;
        text-align: center;
    }

    .bill-to,
    .invoice-details,
    .invoice-summary {
        padding: 10px;
        margin-top: 5px;
        border-left: none;
        border-top: 5px solid #007bff;
    }

    table,
    th,
    td {
        font-size: 12px;
    }

    th,
    td {
        padding: 4px;
    }

    .invoice-footer {
        padding: 10px;
        text-align: center;
        flex-direction: column;
    }

    .invoice-footer div {
        margin-bottom: 10px;
    }

    .button-download {
        width: 100%;
        padding: 10px;
    }
}



@media (max-width: 768px) {
    .SGBUtton {
        font-size: 14px; 
        width: 150px;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .SGBUtton {
        font-size: 12px; 
        width: 130px; 
        padding: 6px; 
    }
}

.headerpdf {
    color: #072C7B;
    font-size: small;
}

.headerpdfT {
    font-size: medium;
    color: #072C7B;
    font-weight: bold;
}

@media (max-width: 768px) {
    .headerpdf {
        color: #072C7B;
        font-size: x-small;
    }

    .headerpdfT {
        font-size: medium;
        color: #072C7B;
        font-weight: bold;
    }
}

/* Additional responsive styles for the table */
.firstTable {
    width: 100%;
    overflow-x: auto;
}

.firstTable table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.firstTable th {
    background-color: #e9ecef;
    color: #333;
}

.firstTable img {
    width: 30px;
    /* Adjust width as needed */
    height: auto;
    /* Maintain aspect ratio */
}

.firstTable th,
.firstTable td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
    /* Ensure content starts from the top */
}

.circle-list-item {
    list-style-type: none;
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.circle-list-item img.icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

@media (max-width: 768px) {

    .firstTable table,
    .firstTable thead,
    .firstTable tbody,
    .firstTable th,
    .firstTable td,
    .firstTable tr {
        display: block;
    }

    .firstTable th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .firstTable tr {
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

    .firstTable td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        text-align: right;
    }

    .firstTable td:before {
        position: absolute;
        top: 50%;
        left: 10px;
        width: calc(50% - 20px);
        white-space: nowrap;
        transform: translateY(-50%);
        font-weight: bold;
        text-align: left;
    }

    .firstTable td:nth-of-type(1):before {
        content: "خدمات العيادات الخارجية";
    }

    .firstTable td:nth-of-type(2):before {
        content: "خدمات الولادة";
    }

    .firstTable td:nth-of-type(3):before {
        content: "درجة الإقامة";
    }

    .firstTable ul {
        padding: 0;
        list-style: none;
    }

    .firstTable li {
        padding: 5px 0;
        display: flex;
        align-items: center;
    }

    .firstTable img {
        margin-right: 10px;
    }
}

.redBack {
    background-color: red;
    height: 100%;
}

.fa-spinner {
    margin-right: 8px;
}























.kadah1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kadah2 {
    margin-top: 20px;
}

.kadah3 {
    text-align: center;
}

.kadah-text {
    font-size: 1.2em;
    color: #ffffff;
    background-color: #072C7B;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: 0 auto;
}

@media (max-width: 767px) {
    .kadah-text {
        font-size: 1em;
        padding: 8px 16px;
        max-width: 90%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .kadah-text {
        font-size: 1.1em;
        padding: 10px 18px;
        max-width: 80%;
    }
}

@media (min-width: 1024px) {
    .kadah-text {
        font-size: 1.2em;
        padding: 12px 24px;
        max-width: 600px;
    }
}




























.financial-data {
    direction: var(--direction, rtl);
    margin: 0 auto;
    text-align: center;
    width: 100%;
}

.financial-data .table-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: blue;
}

.financial-data table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    border: 1px solid #333;
    direction: var(--direction, rtl);
}

.financial-data th,
.financial-data td {
    border: 1px solid #072C7B;
    padding: 10px;
    font-size: 16px;
    text-align: start;
}

.financial-data th {
    background-color: #ccc;
    font-weight: bold;
}

.financial-data tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}


.financial-data .total-label {
    font-weight: bold;
    background-color: #f9f9f9;
    text-align: center;
}

.financial-data .total-value {
    font-weight: bold;
    background-color: #f9f9f9;
    text-align: center;
}



.financial-info-container {
    margin: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.financial-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
}

.financial-table-wrapper {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th,
td {
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 1rem;
}

.total-label {
    font-weight: bold;
    text-align: right;
}

.total-value {
    font-weight: bold;
    color: #072C7B;
}

@media (max-width: 768px) {

    th,
    td {
        font-size: 0.8rem;
        padding: 5px;
    }

    .financial-title {
        font-size: 1.2rem;
    }
}


















.discount-code-section {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin: 20px 0;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.discount-code-section h3 {
    font-size: 1.5rem;
    color: #072C7B;
    margin-bottom: 15px;
    font-weight: bold;
}

.discount-code-input {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    gap: 10px;
}

.discount-code-input input {
    width: 70%;
    max-width: 300px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border-color 0.3s ease;
}

.discount-code-input input:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.5);
}

.discount-code-input .button-validate {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #0056b3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-weight: bold;
    text-transform: uppercase;
}

.discount-code-input .button-validate:hover {
    background-color: #004098;
    transform: scale(1.05);
}

.error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
}

.success-message {
    color: green;
    font-size: 0.9rem;
    margin-top: 10px;
    font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .discount-code-section h3 {
        font-size: 1.2rem;
    }

    .discount-code-input input {
        width: 100%;
    }

    .discount-code-input .button-validate {
        width: 100%;
        padding: 10px;
    }
}
.placeholder-align {
    direction: ltr; /* Set the direction to left-to-right for English */
    text-align: start; /* Ensures the text starts from the left (default for LTR) */
}

/* For Arabic or RTL */
.placeholder-align-rtl {
    direction: rtl; /* Set the direction to right-to-left for Arabic */
    text-align: start; /* Ensures the text starts from the right for RTL */
}
