

@font-face {
    font-family: 'NotoSansArabic-VariableFont_wdth,wght';
    src: url('./fonts/NotoSansArabic-VariableFont_wdth,wght.ttf') format('truetype');
}


.cardsContactUw {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .textCCont {
        height:5px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #5271ff;
    }
    .cardfiras {
        text-align: center;
        width: 80% !important;
    }

    .cardsContactUw {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .cardfiras {
        text-align: center;
        width: 80% !important;
        align-items: center;
    }
}

.womenW {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #FFFFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.cardfiras {
    width: 20%;
    padding: 20px;
    margin-bottom: 20px;
}

.cardfiras:hover {
    opacity: 0.8;
}

.cardfiras img {
    width: 40px;
    height: auto;
    display: block;
    margin: 0 auto;
}


.textCCont {
    height: 40px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #5271ff;
}

.texttCont {
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: #000000;
    line-height: 1.5;
}



















