
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #072C7B;

}



.buttonwhite {
    border: 2px solid #416bf6;
    box-shadow: none;
    border-radius: 22.5px;
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1;
    padding: 13px 43px;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    color: #002677 ;   background-color:    #ffffff;

}


.buttonwhite p {
    color: #002677 ;  
}


.login-form-container {
    background-color: white;
    width: 40%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}


.login-form {
    display: flex;
    flex-direction: column;
}

.user-box {
    margin-bottom: 20px;
}

button {
    font-weight: bold;
}

.login-type {
    margin-top: 20px;
    text-align: center;
}

/* Style for Google sign-in image */
.login-type img {
    cursor: pointer;
    max-width: 100%;
}




























/* Responsive adjustments */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
        align-items: center;
    }

    .login-form-container {
        width: 90%;
        max-width: 400px;
    }
}

.forget-password-btn p {
    text-transform: lowercase;
}

.login-form button {
    text-transform: lowercase;
}

.google-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* General centering for all login-type blocks if applicable */
.login-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}