/* Counter.css */

.bcounter {
    text-align: center;
    margin: 20px;
    padding: 20px;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    /* background: linear-gradient(90deg, #ffffff, #072C7B); */
    background-color: #ffffff;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1), -3px -3px 8px rgba(255, 255, 255, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 130px; /* Set fixed width */
    height: 130px; /* Set fixed height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}   

.bcounter:hover {
    transform: translateY(-5px);
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.2), -3px -3px 12px rgba(255, 255, 255, 0.7);
}

.counter {
    font-size: 32px;
    font-weight: bold;
    color: #072C7B; /* Updated to rose color */
    transition: color 0.3s ease;
}

.bcounter:hover .counter {
    color: #ffc107; /* Updated to rose color for hover state as well */
}

h4 {
    font-size: 1.2em;
    color: #ffc107; /* Updated to rose color */
    margin-top: 10px;
    transition: color 0.3s ease;
}

.bcounter:hover h4 {
    color: #ffffff; /* Updated to rose color for hover state as well */
}

.marwaha {
    color: #000000; /* Updated to rose color for hover state as well */
    font-size: smaller;
}

.counterbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 40px;
    background: #F4F6FA; 
    border-radius: 10px;
}

@media (max-width: 768px) {
    .counterbox {
        flex-direction: column;
    }
}
