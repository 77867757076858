.ayn1 {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background-color: #fefefe;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.ayn2 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
    color: #333;
    font-weight: 700;
}

.ayn3 {
    text-align: center;
    margin-bottom: 20px;
    color: #D8000C;
    font-size: 16px;
    font-weight: 600;
}

.ayn4 {
    text-align: center;
    margin-bottom: 20px;
    color: #4BB543;
    font-size: 16px;
    font-weight: 600;
}

.ayn5 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ayn6 {
    display: flex;
    flex-direction: column;
}

.ayn7 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
}

.ayn8, .ayn9 {
    width: 100%;
    padding: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    color: #555;
    background-color: #fff;
    transition: all 0.3s ease;
}

.ayn8:focus, .ayn9:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    background-color: #f4faff;
}

.ayn9 {
    resize: vertical; 
    min-height: 100px;
}



.ayn8:disabled, .ayn9:disabled {
    background-color: #f0f0f0;
    opacity: 0.8;
    cursor: not-allowed;
}

.ayn10 {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
}

.ayn10:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.ayn10:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.ayn11 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.success-icon {
    color: #4BB543;
    font-size: 24px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .ayn1 {
        padding: 20px;
        margin: 30px 10px;
    }

    .ayn7 {
        font-size: 14px;
    }

    .ayn8, .ayn9 {
        font-size: 14px;
        padding: 12px;
    }

    .ayn10 {
        font-size: 16px;
        padding: 12px;
    }
}
