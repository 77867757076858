.footer-container {
  background-color: #072C7B;
  /* Dark background for contrast */
  color: #ccc;
  /* Light gray text for readability */
  padding: 4rem 1rem;
  /* Spacing around the footer */
}
.footerhe{
  padding-left: 15%;
  padding-right: 15%;

}


.footer-container .footer-section {
  margin-bottom: 2rem;
}

.footer-container h6 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-container ul {
  list-style-type: none;
  padding: 0;
}

.footer-container ul li {
  margin-bottom: 0.5rem;
}

.footer-container ul li a {
  font-size: 1rem;
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-container ul li a:hover {
  color: #fff;
  /* Highlight links on hover */
}

.footer-container .contact-info a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-container .contact-info a:hover {
  color: #fff;
}

.footer-container .payment-logos img {
  height: 24px;
  /* Uniform logo height */
  margin-right: 0.5rem;
  transition: opacity 0.3s ease;
}

.footer-container .payment-logos img:hover {
  opacity: 0.75;
  /* Slightly dim on hover */
}

.footer-container .copyright {
  text-align: center;
  margin-top: 2rem;
  font-size: 1rem;
  color: #666;
  font-weight: bold;
  padding-top: 1rem;
  border-top: 1px solid #072C7B;
  /* Subtle top border */
}

.footer-container .copyright p {
  margin: 0;
}










.baydaS{
  background-color: white;
}

.GoldContabasita {
  font-size: 28px;
  color: #000000;
  font-weight: bold;

}

.centre24 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust based on how much space you want */
  text-align: center;
}
















.Chloe-container {
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem 0;
  background-color: #f4f6fa10;
  /* Dark background for contrast */
  border-radius: 8px;
}

.Chloe1,
.Chloe2,
.Chloe3,
.Chloe4,
.Chloe5 {
  text-align: center;
  padding: 1rem;
  transition: transform 0.3s ease-in-out;
}

.Chloe-icon {
  color: #007bff;
  font-size: 2.5rem;
  margin: 0 auto;
  display: block;
}

.Chloe-text {
  color: #000000;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.Chloe-subtext {
  font-size: 1rem;
  color: #333;
  margin-top: 0.3rem;
}

.Chloe1:hover,
.Chloe2:hover,
.Chloe3:hover,
.Chloe4:hover,
.Chloe5:hover {
  transform: translateY(-5px);
}

@media (max-width: 768px) {
  .Chloe-container {
    flex-direction: column;
    padding: 2rem 0;
  }

  .Chloe1,
  .Chloe2,
  .Chloe3,
  .Chloe4,
  .Chloe5 {
    margin-bottom: 1.5rem;
  }
}









































.lucifer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem 0;
  background-color: #072C7B;
  border-radius: 8px;
}

.lucifer1,
.lucifer2,
.lucifer3,
.lucifer4,
.lucifer5 {
  text-align: center;
  padding: 1rem;
  transition: transform 0.3s ease-in-out;
  width: 200px;
}

.lucifer-logo {
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  object-fit: contain;
}

.lucifer-logo:hover {
  transform: scale(1.1);
}

.lucifer-subtext {
  color: #F4F6FA;
  font-size: 1rem;
  margin-top: 0.3rem;
}

@media (max-width: 768px) {
  .lucifer-container {
    flex-direction: column;
    padding: 2rem 0;
  }

  .lucifer1,
  .lucifer2,
  .lucifer3,
  .lucifer4,
  .lucifer5 {
    margin-bottom: 1.5rem;
  }

  .lucifer-logo {
    width: 80px;
    height: 80px;
  }
}

.godfree {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
}

.partner-heading {
  color: #F4F6FA;
  font-size: 2rem;
  margin-bottom: 20px;
}