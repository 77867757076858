 .talib012 {
     background-color: green;
     justify-content: flex-start;
     align-items: center;
     border-top-left-radius: 8px;
     border-top-right-radius: 8px;
     flex-wrap: nowrap;
     padding: 10px;
 }

 /* Ensure the container takes full viewport height */



 .new-input-container {
     display: flex;
     justify-content: center;
     /* Center the input */
     width: 100%;
 }

 .seckrow {
     width: 100%;
     margin-top: -20px;
     /* Use a negative value to pull the section higher */
 }

 .imagbord {
     padding-top: 5px;
     padding-bottom: 8px;
 }

 .text-column12 {
     flex: 2.80;
     color: #ffffff;
     text-align: right;
 }

 .image-column12 {
     flex: 1.20;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .talib2image12 {
     max-width: 100px;
     height: auto;
     display: block;
     margin: 0 auto;
     border-radius: 8px;
 }

 .talib212 {
     font-size: 1.25rem;
     text-align: justify;
     color: #000000;
     margin: 10px 0;
     padding: 0 20px;
 }




























 @media (max-width: 768px) {
     .search-bar-wrapper {

         max-width: 260px;
     }

     .text-column12 {
         flex: 3;
         color: #ffffff;
         text-align: right;
     }

     .image-column12 {
         flex: 1;
         display: flex;
         justify-content: center;
         align-items: center;
     }



     .talib012 {
         flex-direction: row;



         display: flex;
         justify-content: space-between;
         align-items: center;
         border-top-left-radius: 8px;
         border-top-right-radius: 8px;
         flex-wrap: nowrap;

     }

     .text-column12 {
         text-align: right;
         padding-right: 10px;
     }


     .talib212 {
         font-size: 12px;
     }

     .talib2image12 {
         max-width: 80px;
     }
 }

 @media (max-width: 480px) {
     .talib012 {
         flex-direction: row;



         display: flex;
         justify-content: space-between;
         align-items: center;
         border-top-left-radius: 8px;
         border-top-right-radius: 8px;
         flex-wrap: nowrap;
     }



     .text-column12 {
         text-align: left;
         padding-right: 10px;
     }



     .talib212 {
         font-size: 12px;
     }

     .talib2image12 {
         max-width: 60px;
     }
 }







 .search-input::placeholder {
     font-size: 15px;
     color: #999;
 }


 .treatment-container12 {
     height: 340px;
     /* background-image: url('https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/ban.png?alt=media&token=f25c568c-6b05-4a6a-9619-1afd25bdfb02'); */
     /* background-image: url('https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/nursing.png?alt=media&token=4aab059d-5d51-4818-ad69-40798f176701'); */
     background-image: url('https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/network.png?alt=media&token=d34736f9-3fb8-4533-a3d8-590711cd5b3f');
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     /* border-top-left-radius: 15px;
     border-top-right-radius: 15px; */
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 20px;
 }


 .headersection12 {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     width: 100%;
     padding-right: 10px;
     padding-left: 10px;
     padding-top: 10px;
 }

 .headersection121 {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     width: 100%;
     padding: 10px;
 }



 .abolde {
     font-family: 'Tajawal-Regular';
     color: #ffffff;
     font-weight: bold !important;

 }


















































 .search-bar-wrapper {
     /* 
     position: relative; */
     width: 100%;
     margin: 0 auto;
     /* 
    
     */
     /* Ensure it aligns at the top */
     justify-content: center;
     max-width: 700px;
     text-align: center;
     align-items: center;
     align-items: flex-start;

 }

 .input-group {
     position: relative;
     width: 100%;
 }

 .search-input {
     height: 50px;
     width: 100%;
     padding: 15px;
     border-radius: 50px;
     border: 1px solid #ccc;
     font-size: 18px;
     outline: none;
     transition: all 0.3s ease;
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
 }

 .search-input:focus {
     border-color: #007bff;
     box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
 }

 .clear-button {
     background: none;
     border: none;
     font-size: 1.5rem;
     cursor: pointer;
     position: absolute;

 }



 .dropdown-results {
     position: absolute;
     top: calc(60%);
     left: 0;
     right: 0;
     background-color: #F4F6FA;
     border: 1px solid #ddd;
     border-radius: 12px;
     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
     z-index: 1000;
     max-height: 250px;
     overflow-y: auto;
     padding: 10px 0;
     width: 100%;
 }

 .dropdown-item {
     padding: 15px 20px;
     cursor: pointer;
     transition: background-color 0.3s ease, transform 0.3s ease;
     font-size: 16px;
     color: #333;
 }

 .dropdown-item:hover {
     background-color: #f9f9f9;
     transform: translateX(10px);
     /* Small slide effect */
 }

 @media (max-width: 600px) {
     .search-bar-wrapper {
         width: 100%;
         height: 1150vh;
     }


     .search-input::placeholder {
         font-size: 16px;
         color: #999;
     }


     .headersection12 {
         display: flex;
         justify-content: flex-start;
         align-items: center;
         width: 100%;
         padding-top: 30%;
     }

     .headersection121 {

         display: flex;
         justify-content: flex-start;
         align-items: center;
         width: 100%;
         padding: 10px;
     }




     .search-input {
         font-size: 24px;
         padding: 12px;
     }

     .dropdown-item {
         font-size: 14px;
         padding: 12px 15px;
     }
 }

 .dropdown-item {
     position: relative;
     display: flex;
     align-items: center;
     padding: 10px;
     cursor: pointer;
     background-color: #f9f9f9;
     border: 1px solid #ddd;
     margin-bottom: 5px;
     overflow: hidden;
 }




 .item-content {
     height: 60px;
     position: relative;
     z-index: 2;
     color: #000000;
     font-size: 16px;
 }

 .service-image {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     object-fit: cover;
     opacity: 0.3;
     z-index: 1;
 }

 .service-title {
     z-index: 2;
     position: relative;
     padding-left: 15px;
 }



 .new-input-container {
     display: flex;
     align-items: center;
     width: 100%;
 }

 .new-input-container .input-group {
     display: flex;
 }

 .new-input-container .input-group .search-input {
     flex-grow: 1;
     margin-right: 10px;
 }

 .new-input-container .filter-btn {
     background-color: #007bff;
     color: white;
     border: none;
     cursor: pointer;
 }

 .new-input-container .filter-btn:hover {
     background-color: #0056b3;
 }

 .filter-btn-desktop {
     display: none;
 }

 .filter-btn-mobile {
     display: block;
 }

 .filter-btn-mobile1 {
     display: block;
 }

 @media (min-width: 768px) {


     .dropdown-results {
         top: calc(50%);

     }

     .filter-btn-mobile {
         display: none;
     }

     .filter-btn-mobile1 {
         display: none;
     }

     .headersection12 {
         display: flex;
         justify-content: flex-start;
         align-items: center;
         width: 100%;
         padding: 10px;
     }

     .headersection121 {
         width: 30%;
         padding: 10px;
     }

     .filter-btn-desktop {
         display: block;
     }

     .Contdeps {
         padding: 5%;
     }

     .talib112 {
         font-size: 20px;
         color: #ffffff;

     }
 }

 .talib112 {
     padding-top: 20px;
     font-size: 20px;
     text-align: start;
     color: #ffffff;
     margin-bottom: 15px;
     font-weight: bold !important;

 }

 .filter-btn-mobile {
     background-color: #007BFF;
     color: #ffffff;
     border: none;
     border-bottom-left-radius: 28px;
     border-top-left-radius: 28px;
     ;
     cursor: pointer;
     font-size: 32px;
     transition: background-color 0.3s ease, transform 0.3s ease;
     position: fixed;
     top: 350px;
     right: -10px;
     z-index: 9;
 }

 .filter-btn-mobile1 {
     background-color: #007BFF;
     color: #ffffff;
     border: none;
     border-bottom-left-radius: 28px;
     border-top-left-radius: 28px;
     ;
     cursor: pointer;
     font-size: 32px;
     transition: background-color 0.3s ease, transform 0.3s ease;
     position: fixed;
     top: 430px;
     right: -10px;
     z-index: 9;
 }

 .filter-btn-mobile:hover {
     background-color: #008e28a6;
     transform: scale(1.03);
 }

 .filter-btn-mobile1:hover {
     background-color: #008e28a6;
     transform: scale(1.03);
 }

 /* Hide mobile filter button on desktop */
 @media (min-width: 768px) {
     .filter-btn-mobile {
         display: none;
     }

     .filter-btn-mobile1 {
         display: none;
     }
 }




 .Contdeps {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     padding: 40px;
     border-radius: 20px;
     width: 100%;
     margin: 0 auto;
 }






















 .no-results-message {
     background-color: #f9f9f9;
     color: #555;
     font-size: 16px;
     font-weight: 500;
     padding: 15px;
     border: 1px solid #e0e0e0;
     border-radius: 8px;
     text-align: center;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 }

 .no-results-message:hover {
     background-color: #f0f0f0;
     transition: background-color 0.3s ease;
 }