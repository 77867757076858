.ajwad1 {
  display: flex;
  flex-direction: row;
}

.ajwad2 {
  flex: 1;
}

.ajwad3 {height: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ajwad2 ul, .ajwad3 ul {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0; /* To ensure no padding on the right side */
}

.ajwad2 ul li, .ajwad3 ul li {
  position: relative;
  padding-left: 20px;
  padding-right: 20px; /* To ensure padding on both sides */
}

.ajwad2 ul li::before, .ajwad3 ul li::before {
  content: '•';
  position: absolute;
  font-size: 1.5em;
  line-height: 1;
  top: 0;
}

.ltr .ajwad2 ul li::before, .ltr .ajwad3 ul li::before {
  left: 0;
  color: #072C7B;
}

.rtl .ajwad2 ul li::before, .rtl .ajwad3 ul li::before {
  right: 0;
  color: #072C7B;
}

.normalText {
  font-size: 14px;
}

.btnwidth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust this as needed to fit your layout */
}

.ContactU.closed {
  display: none;
  transition: all 0.5s ease;
}

.ContactU.open {  
  background-color: #F4F6FA;
  display: block;
  transition: all 0.5s ease;
}

.smalldesc {
  color: #000000;
  font-size: 14px;
}

.carrdss_container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  gap: 20px;
  padding: 10px;
  justify-content: center;
  align-content: center;
}

.subTitleBCont {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  width: 50%;
}

.subTitleMidelCont {
  font-size: 24px;
  color: #000000;
  font-weight: bold;
}

.ikbal_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
  background-color: #F4F6FA;
}
/* Security.css */
.line {
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.ikbal {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.ikbal_img {
  flex: 0 0 150px;
  height: 50%;
  overflow: hidden;
}

.ikbal_img img {
  width: 50%;
  height: auto;
  object-fit: cover;
}

.ikbal_text {
  flex: 1;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.button_collapses {
  margin-bottom: 5px;
  background-color: #f4f4f4;
  border: 1px solid #072C7B;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.samerow {
  display: flex;
  justify-content: space-between;
  /* Spread items across the container */
}

.samecolumn {
  margin-right: 20%;
  margin-left: 20%;
}

.samecolumn ul {
  list-style-type: none;
}

.samecolumn ul li {
  display: flex;
}

.image {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.samecolumn ul li::before {
  content: '•';
  color: #072C7B;
  font-size: 70px;
  line-height: 1;
}

.samerow img {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px;
}

.link {
  color: #072C7B;
}

.card_sahem {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 10px;
  justify-content: center;
  align-content: center;
}

.titlecollaps {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 24px;
  color: #000000;
  font-weight: bold;
  text-align: left;
  display: flex;
}

@media (max-width: 600px) {
  .titlecollaps {
      margin-left: 10px;
      flex-grow: 1;
      font-size: 22px;
      color: #000000;
      font-weight: bold;
      text-align: left;
      display: flex;
  }

  .card_sahem {
      grid-template-columns: repeat(2, 1fr);
  }

  .carrdss_container {
      grid-template-columns: repeat(2, 1fr);
  }

  .smalldesc {
      font-size: 16px;
      color: #000000;
  }

  .ikbal_container {
      grid-template-columns: 1fr;
  }

  .ikbal_img img {
      width: 30%;
      height: auto;
      object-fit: cover;
  }

  .cards {
      flex-direction: column;
  }

  .ikbal_img {
      width: 100%;
      flex: none;
  }

  .subTitleBCont {
      font-size: 18px;
      color: #000000;
      font-weight: bold;
      width: 100%;
  }

  .normalText {
      font-size: 18px;
      color: #000000;
      width: 100%;
  }

  .subTitleMidelCont {
      font-size: 18px;
      color: #000000;
      font-weight: bold;
  }

  .ikbal {
      flex-direction: column;
  }

  .samerow {
      flex-direction: column;
      align-items: center; 
  }

  .samecolumn {
      margin-left: 0;
      margin-right: 0;
      text-align: center;
  }

  .samecolumn,
  .image img {
      max-width: 100%; 
      height: auto; 
      display: block;
  }
}

.columncontact {
  display: flex;
  flex-direction: column;
}

.rowcontact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.subTitleMidelCont {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}

.subTitleMidelCont:first-child {
  margin-right: 10px;
}

.subTitleMidelCont:last-child {
  margin-left: 10px;
}

.btnsubTitleMidelCont {
  padding: 8px 16px;
  border: none;
  background-color: #0056B3;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.btnsubTitleMidelCont:hover {
  background-color: #051e4b;
}
