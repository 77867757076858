@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  --primary-color: #28527a; 
  --hover-color: #8ac4d0;
  --button-text-color: #ffffff;
  --input-border-color: #ced4da;
}


.user-info-section {
  width: 80%;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}


.user-info-form fieldset {
  border: 1px solid var(--input-border-color);
  margin-top: 10px;
  padding: 20px;
}

.user-info-form legend {
  padding: 0 10px;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--button-text-color);
}

.user-info-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.user-info-form input,
.user-info-form select,
.user-info-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.user-info-form button {
  width: 100%;
  padding: 10px 0;
  margin-top: 20px;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
}



.user-info-form textarea {
  min-height: 100px;
  resize: vertical;
}

.user-info-form input:focus,
.user-info-form select:focus,
.user-info-form textarea:focus,
.user-info-form button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--hover-color);
}

@media (max-width: 768px) {
  .user-info-form fieldset {
    padding: 10px;
  }

  .user-info-form legend {
    font-size: 1rem;
  }

  .user-info-form input,
  .user-info-form select,
  .user-info-form textarea,
  .user-info-form button {
    padding: 12px;
  }
}



.cont {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-actions {
  display: flex;
  flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: 20px auto;
   padding: 20px;
  max-width: 800px;
 }

 


























.send2 {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.send2:hover {
  background-color: #0057b38c;
}

.send2:active {
  background-color: #0056b3;
  border-color: #0056b3;
  color: #ffffff;
}

.send2:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}






















.buttonsTghazal:hover,
.buttonsTghazal:focus {
  background-image: linear-gradient(to right, #0056B3, #0741A2);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.buttonsTghazal:active {
  background-color: #072C7B;
  color: #FFFFFF;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}


.buttonsTghazal:active {
  background-image: linear-gradient(to right, #b81717 0%, #b81717 50%, #b81717 100%);

  transform: scale(0.95);
  /* Decrease size slightly when pressed */
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
  /* Adjust shadow when pressed */
}

.buttonsTghazal.disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}


 



.button-yellow {
  padding: 10px 20px;
  color: white;
  background-color: #416bf6;
  font-family: 'Tajawal-Regular';
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}



.button-yellow:disabled {
  background-image: linear-gradient(to right, #0740a200 0%, #0740a200 50%, #0740a200 100%);
  background-color: #ffffff00;
  color: #ffffff00;
  cursor: not-allowed;
  box-shadow: none;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  height: 1px;
}















.error-message {
  display: flex;
  align-items: center;
  background-color: #fdd;
  border: 1px solid #f00;
  color: #f00;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-icon {
  font-size: 24px;
  margin-right: 12px;
}

.error-text {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.check1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0; /* Adjust the margin as needed */
}

.check2 {
  display: flex;
  align-items: center;
  margin: 0 10px; 
 }

.check3 {
  margin: 0 10px; 
   font-size: 16px; 
   font-weight: bold;
 }
@media (max-width: 768px) {
  .romel {
    display: none;
  }
}
.Test{
  background-color: #b81717;
}































.TkhileAride {
  padding: 12px;
  width: 150px;
  background-image: linear-gradient(to right, #007BFF 0%, #007BFF 50%, #007BFF 100%);
  color: #F4F6FA;
  font-size: 16px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px #0000004d;
}

.TkhileAride:hover,
.TkhileAride:focus {
  background-image: linear-gradient(to right, #007BFF, #007BFF);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.TkhileAride:active {
  background-color: #007BFF;
  color: #F4F6FA;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.TkhileAride p {
  color: #F4F6FA;
  font-size: x-small;
}

@media (max-width: 600px) {
  .TkhileAride {
    width: 150px;
    padding: 10px;
    font-size: 14px;
  }

  .TkhileAride p {
    font-size: small;
  }
}

@media (max-width: 400px) {
  .TkhileAride {
    width: 150px;
    padding: 8px;
    font-size: 12px;
  }

  .TkhileAride p {
    font-size: smaller;
  }
}


@media (max-width: 600px) {
  .TkhileAride {
    width: 150px;
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .TkhileAride {
    width: 150px;
    padding: 8px;
    font-size: 12px;
  }
}




.custom-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; 
}

.custom-spinner-icon {
  font-size: 20px; 
  color: #3498db;
  margin-right: 5px;
}

.custom-spinner-text {
  font-size: 16px; 
  font-weight: bold;
  color: #2c3e50; 
} 
.custom_spinner_text_white {
  color: white; 
  font-size: 16px; 
  text-align: center;
}

@media (max-width: 768px) {
  .custom_spinner_text_white {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .custom_spinner_text_white {
    font-size: 12px; 
  }
}






























.UserDataTable {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.TableHeader {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
  padding: 8px;
  text-align: inherit; /* Dynamically inherit direction */
}

.TableRow:nth-child(even) {
  background-color: #f9f9f9;
}

.TableRow:hover {
  background-color: #f1f1f1;
}

.TableCell {
  padding: 8px;
  border-top: 1px solid #ddd;
  text-align: inherit; /* Inherit direction dynamically */
}

/* Responsive Design */
@media (max-width: 768px) {
  .UserDataTable,
  .TableHeader,
  .TableCell {
    font-size: 0.8rem;
  }

  .TableCell {
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .UserDataTable {
    width: 100%;
  }

  .TableHeader,
  .TableCell {
    padding: 4px;
  }
}















































/* General styling for the fieldset */
fieldset {
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

/* Labels */
fieldset label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #333;
}

/* Input fields and select dropdowns */
fieldset input[type="text"],
fieldset input[type="email"],
fieldset input[type="tel"],
fieldset select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

fieldset input:focus,
fieldset select:focus {
  outline: none;
}

/* Error message styling */
.error-message {
  display: flex;
  align-items: center;
  background-color: #ffe6e6; /* Light red background */
  color: #d9534f; /* Red text */
  padding: 10px;
  border: 1px solid #d9534f; /* Red border */
  border-radius: 4px;
  margin-top: -10px; /* Reduce gap */
  margin-bottom: 15px; /* Space below */
  font-size: 13px;
}

.error-icon {
  margin-right: 8px; /* Space between icon and text */
  font-size: 16px;
}

.error-text {
  margin: 0;
}

/* Disabled fields styling */
fieldset input:disabled,
fieldset select:disabled {
  cursor: not-allowed;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  fieldset {
    padding: 15px;
  }

  fieldset label {
    font-size: 12px;
  }

  fieldset input,
  fieldset select {
    font-size: 13px;
  }
}
