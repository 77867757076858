.otp-form {
  display: flex;
  flex-direction: column; 
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.otp-form-header {
  text-align: center;
  margin-bottom: 20px;
}

.otp-form-title {
  font-size: 1.8em;
  color: #072C7B;
  margin-bottom: 5px;
}

.otp-form-subtitle {
  font-size: 1em;
  color: #555;
  margin-bottom: 15px;
}

/* OTP input group styles */
.otp-input-group {
  width: 100%;
}

.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.otp-label {
  font-size: 1em;
  color: #333;
  margin-bottom: 5px;
}

.otp-input {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 1.2em;
  text-align: center;
  width: 120px;
  transition: border-color 0.3s ease;
}

.otp-input:focus {
  border-color: #072C7B; /* Change border color on focus */
  outline: none;
}

/* Error message styling */
.error-message {
  font-size: 0.9em;
  color: red;
  display: flex;
  align-items: center;
}

















.otp-submit-btn {
  background-color: #072C7B;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.otp-submit-btn:hover {
  background-color: #0A417A;
}


@media (max-width: 600px) {
  .otp-form {
    max-width: 100%;
    padding: 15px;
  }

  .otp-form-title {
    font-size: 1.5em;
  }

  .otp-submit-btn {
    font-size: 0.9em;
  }

  .otp-input {
    width: 100%;
    font-size: 1.1em;
  }
}

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  background-color: #072C7B;
}


.signup-form {
  max-width: 400px;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
}

.signup-form label {
  font-size: 16px;
  margin-bottom: 5px;
}

.signup-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signup-form p {
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
}





.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: -10px;
}









.login-btn {
  padding: 12px;
  width: 150px;
  background: rgb(244, 245, 250);
  color: #072C7B;
  font-size: 16px;
  border: 2px solid #072C7B; 
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px #03a9f4;;
}


.login-btn:active {
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}
















.Registration-btn {  padding: 12px;

  width: 150px;
  background-image: linear-gradient(to right, #007BFF 0%, #007BFF 50%, #007BFF 100%);
  color: #F4F6FA;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px #0000004d;
}
 
.Registration-btn:hover,
.Registration-btn:focus {
  background-image: linear-gradient(to right, #007BFF, #007BFF);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.Registration-btn:active {
  background-color: #007BFF;
  color: #F4F6FA;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}


.name-container {
  display: flex;
  justify-content: space-between;
}
.faragh{
  padding: 5px;

}
.name-container > div {
  flex: 1;
  margin-right: 10px;
}

.name-container > div:last-child {
  margin-right: 0;
}




.center-section {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center; 
  margin: 0 auto; 
  gap: 15px;
  width: 100%; 
  max-width: 400px;
  padding: 20px; 
}

.center-section label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.center-section input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.center-section .error-message {
  font-size: 14px;
  color: red;
  margin-top: 5px;
  text-align: center;
}
