.backgroundsnowwhite {
  background-color: #f0f0f0;

}

.button_collapsess {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin: 10px 0;

}

.button_collapsess:hover {
  background-color: #f0f0f0;
}

.subtitelcollaps {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.normalcolapsText {
  font-size: 16px;
  color: #000000;
  width: 100%;
}

.ltr .normalcolapsText {
  text-align: left;
  padding-left: 10%;
  padding-right: 6%;
}

.ltr .subtitelcollaps {
  text-align: left;
  padding-left: 4%;
}

.rtl .normalcolapsText {
  text-align: right;
  padding-right: 6%;

}

.rtl .subtitelcollaps {
  text-align: right;
  padding-right: 4%;

}

.ltr .titlenormalcolapsText {
  text-align: left;
  padding-left: 13%;

}

.rtl .titlenormalcolapsText {
  text-align: right;
  padding-right: 13%;
  padding-left: 5%;

}

/* li ta7t l3anwen l azra2 fwt7 */


.titlenormalcolapsText {
  font-size: 16px;
  color: #000000;
  width: 100%;
  font-weight: bold;

}



@media (max-width: 600px) {
  .subtitelcollaps {
    margin-left: 10px;
    flex-grow: 1;
    font-size: 18px;
    color: #000000;
    font-weight: bold;
  }
}

.widthbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}



.buttonRow {
  display: flex;
  justify-content: space-between;
}

.widthbutton {
  flex: 1;
  margin: 0 5px;
}

.sendbtn {
  width: 100%;
}
.ltr .ULS {
  text-align: left;
  padding-left: 9%;
  padding-right: 5%;

}

.rtl .ULS {
  text-align: right;
  padding-right: 9%;
  padding-left: 5%;

}

.ULS {
  font-size: 24px;
  color: #072C7B;
  width: 100%;
  font-weight: bold;
}



.ltr .normaltst {
  text-align: left;
  /* padding-left: 7%;
  padding-right: 3%; */

}

.rtl .normaltst {
  text-align: right;
  /* padding-right: 7%;
  padding-left: 3%; */
}

.normaltst {
  font-size: 16px;
  color: #000000;
  width: 100%;
}

/* l3anwen lwasat */

.ltr .titlenormalcolapsTextULS {
  text-align: left;
  padding-left: 11%;
  padding-right: 5%;

}

.rtl .titlenormalcolapsTextULS {
  text-align: right;
  padding-right: 11%;
  padding-left: 5%;
}

.titlenormalcolapsTextULS {
  font-size: 18px;
  color: #000000;
  width: 100%;
  font-weight: bold;

}
[dir="ltr"].button_mada span {
  display: inline-block;
  transition: transform 0.3s ease;
}

[dir="rtl"].button_mada span {
  display: inline-block;
  transition: transform 0.3s ease;
}

[dir="ltr"].button_mada:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}

[dir="rtl"] .button_mada:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);
}

[dir="rtl"] .button_mada:active {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}

[dir="ltr"].button_mada:active {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}



[dir="rtl"] .button_mada {
  flex-direction: row-reverse;
  background-color: #ffffff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  text-align: start;
  text-align: right;
  transition: background-color 0.3s ease;

}

[dir="ltr"] .button_mada {
  text-align: left;
  background-color: #ffffff;
  text-align: end;
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;

}






























.bannerVideo {
  width: 100%;
  height: 100%; 
  object-fit: cover;
  position: absolute;
  top: 0; 
  left: 0;
  z-index: 1; 
}
.PlayButton {
  position: absolute;
  top: 50%; /* Center vertically by default */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 50px; /* Default size for larger screens */
  color: #ffffff;
  z-index: 2; 
  outline: none;
  transition: transform 0.3s ease-in-out;
}

.PlayButton.active {
  transform: translate(-50%, -50%) scale(1.1); 
}

.PlayButton:hover {
  transform: translate(-50%, -50%) scale(1.2); 
}

.icon.play:before {
  content: '▶️';
}

.icon.pause:before {
  content: '⏸️';
}

.bannersol {
  position: relative;
  width: 100%;
  height: 500px; /* Fixed height for desktop view */
  overflow: hidden;
  background-color: #ffffff; /* Fallback background color in case the video doesn't load */
}

@media (max-width: 768px) {
  .bannerVideo {
    top: 0; /* Align the video to the top of the container */
    height: 100%; /* Ensure the video fully covers the container */
    object-fit: cover; /* Maintain cover behavior on smaller screens */
  }

  .bannersol {
    height: 300px; /* Adjust the banner height for smaller screens */
  }

  .PlayButton {
    font-size: 40px; /* Adjust font size for tablets */
    top: 30%; /* Move the button closer to the top */
    left: 50%; 
    transform: translate(-50%, -30%);
  }
}

@media (max-width: 480px) {
  .bannerVideo {
    top: 0;
    height: 100%;
    object-fit: cover; /* Ensure the video fully covers the container */
  }

  .bannersol {
    height: 250px; /* Further reduce the height for smaller devices */
  }

  .PlayButton {
    font-size: 30px; /* Further reduce the size of the play/pause button */
  }
}
