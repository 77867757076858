





.grid-containerClient {

  color: white;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
}

.grid-item:hover .item-image {
  transform: scale(1.1);
}

.grid-item:hover .item-caption {
  opacity: 1;
}

.grid-item {
  text-align: center;
  position: relative;

}


.item-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;
}

.item-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-containerClient {
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .ourclientB {
    padding: 50px;
    width: auto;
    height: 90%;
    background-size: cover;
    min-height: 20vh;

    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    border-radius: 1pc;
  }

  .grid-containerClient {  gap: 30px; /* Add gap between the grid items */

    display: grid;
    justify-content: center;
    padding: 10%;
    grid-template-columns: repeat(1, 1fr);
  }

}