h1 {
  color: #2a2a2a;
  text-align: center;
}

.backgroundsnowwhite {
  background-color: #F4F6FA;
  display: flex;
  flex-direction: column;
  background-color: #F4F6FA;
  padding: 10px;
}

.gellule {
  padding: 3%;
  background-color: #ffffff;
  width: 90%;

}
/* .gellule p{
background-color: red;
} */
.button_collapsess {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin: 10px 0;

}

.button_collapsess:hover {
  background-color: #f0f0f0;
}

.subtitelcollaps {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.normalcolapsText {
  font-size: 16px;
  color: #000000;
  width: 100%;
}

.ltr .normalcolapsText {
  text-align: left;
  padding-left: 6%;
}


.ltr .subtitelcollaps {
  text-align: left;
  padding-left: 4%;
}

.ltr .titlenormalcolapsTextt {
  text-align: left;
  padding-left: 12%;
  color: #666;

}




.rtl .normalcolapsText {
  text-align: right;
  padding-right: 6%;

}


.rtl .subtitelcollaps {
  text-align: right;
  padding-right: 4%;

}

/* Common styles */
.sostitel {
  font-size: 17px;
  color: #666;
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

/* RTL specific styles */
.rtl .sostitel {
  font-size: 17px;
  display: flex;
  flex-direction: column;

  padding-right: 12%;
}

.ltr .sostitel {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 12%;
}



.titlenormalcolapsText {
  font-size: 16px;
  color: #007bff;
  width: 100%;

}

.sostext {
  font-size: 16px;
  color: #666;
  width: 100%;
}

.ltr .sostext {
  text-align: left;
  padding-left: 15%;
  font-size: 16px;
  width: 100%;
  color: #666;

}

.rtl .sostext {
  text-align: right;
  padding-right: 15%;
  font-size: 16px;
  color: #666;

}



@media (max-width: 600px) {
  .subtitelcollaps {
    margin-left: 10px;
    flex-grow: 1;
    font-size: 18px;
    color: #000000;
    font-weight: bold;
  }
}

.widthbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 5px;
  width: 100%;
}



.buttonRow {
  display: flex;
  justify-content: space-between;
}

.widthbutton {
  flex: 1;
  margin: 0 5px;
}

.sendbtn {
  width: 120px;
  padding: 10px;
  font-size: 14px;

  transition: background-color 0.3s ease;
}



@media (max-width: 1200px) {
  .sendbtn {
    width: 110px;
    padding: 9px;
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .sendbtn {
    width: 100px;
    padding: 8px;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .sendbtn {
    width: 90px;
    padding: 7px;
    font-size: 11px;
  }
}

@media (max-width: 576px) {
  .sendbtn {
    width: 80px;
    padding: 6px;
    font-size: 10px;
  }
}











.ltr .titlenormalcolapsTextW {
  text-align: left;
  padding-left: 12%;

}

.rtl .titlenormalcolapsTextW {
  text-align: right;
  padding-right: 12%;

}

.titlenormalcolapsTextW {
  font-size: 16px;
  color: #F4F6FA;
  width: 100%;
  font-weight: bold;

}



.details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.item-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80%;
  max-width: 600px;
}

.item-card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.item-card p {
  margin-top: 10px;
  font-size: 16px;
}

.price {
  font-weight: bold;
  color: #4A90E2;
}

.no-data {
  font-style: italic;
}

.TitlContwo {
  font-size: 32px;
  color: #000000;
  font-weight: bold;
}

.titlecollapsee {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 22px;
  color: #000000;
  font-weight: bold;
  text-align: left;
  display: flex;
  padding-right: 10px;
  padding-bottom: 10px;

}

.titlecollapseeWe {
  margin-left: 10px;

  flex-grow: 1;
  font-size: 18px;
  color: #F4F6FA;
  font-weight: bold;
  text-align: left;
  display: flex;
  padding-right: 10px;
}


@media (max-width: 600px) {



  .titlecollapsee {
    margin-left: 10px;
    flex-grow: 1;
    font-size: 22px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    display: flex;

  }

  .titlecollapseeWe {
    margin-left: 10px;
    flex-grow: 1;
    font-size: 18px;
    color: #F4F6FA;
    font-weight: bold;
    text-align: left;
    display: flex;

  }



  .det_gr {
    grid-template-columns: 1fr;
  }

  .sostitel {
    font-size: 14px;
    flex-direction: column;
    /* Overrides any other direction to ensure stacking */

  }

  .rtl .sostitel {
    font-size: 14px;
    flex-direction: column;
    /* Overrides any other direction to ensure stacking */

    font-weight: bold;
    padding-left: 5px;

  }

  .ltr .sostitel {
    font-size: 14px;
    flex-direction: column;
    padding-right: 5px;
    font-weight: bold;

  }














  .sostext {
    font-weight: normal;

    font-size: 14px;
  }

  .rtl .sostext {
    font-weight: normal;

    font-size: 14px;
  }

  .ltr .sostext {

    font-weight: normal;


    font-size: 14px;
  }
}




.det_gr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 20px 0;
}

.grid-cell {
  background-color: #f8f8f8;
  padding: 10px;

}

.Bluedive {
  background-color: #F4F6FA;
  height: 30px;
  align-items: center;
  align-self: center;
}

.circle-list-item {
  position: relative;
  list-style-type: none;
}

.circle-list-item::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #000000;
  border-radius: 50%;
}

.ltr .circle-list-item {
  padding-left: 20px;
}

.ltr .circle-list-item::before {
  left: 0;
}

.rtl .circle-list-item {
  padding-right: 20px;
}

.rtl .circle-list-item::before {
  right: 0;
}


.habaa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 5px;
  width: 100%;
  background-color: #F4F6FA;
}


@media (max-width: 768px) {
  .TitlContwo {
    font-size: 20px;
  }

  .titlecollapsee {
    font-size: 16px;
  }

  .titlecollapseeWe {
    font-size: 16px;
  }


  .titlenormalcolapsText {
    font-size: 14px;


  }

  .grid-cell {
    background-color: #f8f8f8;
    padding: 10px;



    display: flex;
  }

}







/* Details.css */







.iremember {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iremember ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}






























.iconListItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 10px;
}

.iconListItem img {
  width: 15px;
  height: 15px;
  margin-top: 5px;
}

.ltr .iconListItem img {
  margin-left: 6px;
}

.ltr .iconListItem p {
  text-align: left;
  margin: 0;
  line-height: 1.5;
  flex: 1;
  margin-left: -4px;
}


.rtl .iconListItem img {
  margin-right: 6px;

}

.rtl .iconListItem p {
  text-align: right;
  margin: 0;
  line-height: 1.5;
  flex: 1;
  margin-right: 0px;
  margin-right: -4px;
}















.iconTrue {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-right: 1%;
  padding-left: 1%;
  position: relative;
  margin-top: 3px;
}

.iconImage {
  width: 15px;
  height: 15px;
  margin-top: 3px;
}

.maw3edh {
  margin-top: 5px;
  line-height: 1.5;
  flex: 1;
}

.rtl .iconImage {
  margin-left: 10px;
}

.jaeita {
  width: 15px;
  height: 15px;
  margin-top: 3px;
}

.rtl .maw3edh {
  text-align: right;
}

.ltr .iconImage {
  margin-right: 10px;
}

.ltr .maw3edh {
  text-align: left;
}