
[dir="rtl"] .button-collapse {
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
  text-align: start;
  text-align: right;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.1);

}

[dir="ltr"] .button-collapse {
  text-align: left;
  text-align: end;
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.1);

}

[dir="rtl"] .textCont {
  text-align: right;
}

[dir="ltr"] .textCont {
  text-align: left;
}

.backgroundwhiteASS {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 800px;
  border-top-left-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid #ccc;

}

.backgroundwhiteASS img {

  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin: 10px 0;
}


.bannersol {
  border-top-left-radius: 10px;

  background-size: cover;
  background-position: center;
  height: 320px;
  position: relative;
  text-align: center;
}



.TitlCont {
  font-size: 32px;
  color: #000000;
  font-weight: bold;
}

.TitlContw {
  font-size: 32px;
  color: #000000;
  font-weight: bold;
}


.textBCont {
  font-size: 16px;
  color: #000000;
}

.textCont {
  color: #333;
  font-weight: bold;
  font-size: medium;
}

.textWCont {
  font-size: 16px;
  color: #F4F6FA;
}


.button-collapse:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);
  /* Thin line-like shadow at the bottom */

}

.button-collapse:active {
  background-color: #F4F6FA;
}


.bannerText span {
  display: block;
  margin-bottom: 10px;
}




.GoldCont {
  font-size: 38px;
  color: #000000;
  font-weight: bold;

}






@media (max-width: 600px) {

  .TitlCont {
    font-size: 28px;
    color: #F4F6FA;
    font-weight: bold;
  }


  .GoldCont {
    font-size: 28px;
    color: #000000;
    font-weight: bold;
  
  }




  .textCont {
    color: #333;
    font-weight: bold;
    font-size: medium;
  }

  .textWCont {
    font-size: 18px;
    color: #F4F6FA;
  }

  .textBCont {
    font-size: 16px;
    color: #000000;
  }

  .cardContactU {
    width: 80% !important;
    align-self: center;
  }

  .cardsContactU {
    flex-direction: column !important;
    justify-content: center;
  }



  .backgroundwhiteASS {
    max-width: 90%;
    margin: 10px auto;
  }
 
}



.ContactU {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardsContactU {
  display: flex;
  justify-content: space-around;
  /* Adjust as needed */
  flex-wrap: wrap;
  margin-top: 20px;
  /* Adjust margin as needed */
}

.cardContactU {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;


}

.cardContactU:hover {
  opacity: 0.8;
  /* Reduces opacity, making the content appear lighter */
}

.cardContactU img {
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}







.sendbtn {
  font-family: 'Tajawal-Regular';
  background-color: #fff;
  border: 1px solid #0056B3;
  color: #072C7B;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 30px;

}

.sendbtn:hover {
  background-color: #F4F6FA;
  color: #072C7B;
}

.sendbtn p {
  font-family: 'Tajawal-Regular';
}


.socialIcons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.socialIcons a {
  color: #072C7B;
  font-size: 400%;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s, transform 0.3s;
}

.socialIcons a:hover {
  color: #072C7B8f;
  transform: scale(1.1);
}


.button-collapse span {
  display: inline-block;
  transition: transform 0.3s ease;
}




.TitleConta {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}


.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background-color: #f5f5f5;
  padding: 20px 0;
}

.main {
  padding: 20px 0;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

legend {
  color: #000;
  padding: 0;
}

a img,
iframe {
  border: none;
}



.newsletter-section {
  background-color: #0056B3;
  padding: 40px;
  text-align: center;
  color: #fff;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #072C7B;
  border-radius: 30px;

}






.buttonpse {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f4f6fa;
  width: 80%;
  margin: auto;
}

[dir="rtl"] .buttonpse,
[dir="ltr"] .buttonpse {
  flex-direction: row;
}

.buttonpse button {
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonpse button:hover {
  background-color: #003e80;
}



.centered-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.centered-section .section-title {
  font-size: 24px;
  color: #072C7B;
  font-weight: bold;
  margin-bottom: 10px;
}
 
.centered-section .contact-info {
  font-size: 16px;
  color: #555;
  margin: 5px 0;
}

.downan {
  padding-top: 10%px;
}























.contact-section {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-section .section-title {
  font-size: 24px;
  color: #072C7B;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-section .contact-info {
  margin-bottom: 20px;
}

.contact-section .contact-info p {
  margin: 5px 0;
}
.contact-section p {
  font-size: 16px;
  color: #555;
  margin: 5px 0;
  text-align: center;
}
.contact-subtitle {
  font-size: 20px;
  color: #072C7B;
  font-weight: bold;
  margin: 20px 0 10px;
}

.button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

 .button-text {
  flex: 1;
  font-size: 16px;
  color: #555;
  text-align: center;
  /* Center-aligns the text */
}
































































































































