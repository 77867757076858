/* My Contracts Container */
.my-contracts-container {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* Remove any margin at the top */
  margin-top: 0;
}
  
  /* PDF List */
  .pdf-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* PDF Item */
  .pdf-item {
    margin: 10px 0;
    width: 100%;
  }
  
  .pdf-item a {
    text-decoration: none;
    color: #4285f4;
    font-weight: bold;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .my-contracts-container {
      width: 100%;
      padding: 15px;
    }
  }
  




  



/* Start of page contract details */
.contract-details-start {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  text-align: right; /* Align text to the right for Arabic */
  font-size: 1rem;
  color: #333333;
}


.copy-button {
  background-color: #F4F6FA; /* Set background color */
  border: 1px solid #d1d9e6; /* Light border to give a subtle definition */
  color: #007bff; /* Button icon color */
  border-radius: 5px; /* Rounded corners for a softer look */
  padding: 6px 10px; /* Padding for better spacing */
  cursor: pointer; /* Pointer cursor to indicate it's clickable */
  font-size: 1rem; /* Font size for the icon */
  display: inline-flex; /* Align icon properly */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
}

.copy-button:hover {
  background-color: #e2e8f0; /* Slightly darker background on hover */
  border-color: #b8c3d5; /* Darken border on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Add a subtle shadow on hover */
}

.copy-button:active {
  background-color: #d0d7e5; /* Even darker background when pressed */
  border-color: #a7b5cc; /* Darken border even more on press */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2); /* Inset shadow to mimic press effect */
}

.copy-button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); /* Custom focus outline */
}






  /* Container for the entire contracts section */
.my-contracts-container {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Title of the contracts section */
.my-contracts-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333333;
}

/* Message when no contracts are found */
.no-contracts-message {
  text-align: center;
  font-size: 1.25rem;
  color: #888888;
  margin-top: 20px;
}

/* List of contracts */
.contracts-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Individual contract item */
/* Individual contract item */
.contract-item {
  display: flex; 
  justify-content: space-between;
  align-items: center;
  background-color: #F4F6FA;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  /* Ensure contract items are aligned from the top */
  margin-top: 0;
}

/* Contract details such as reference number and date */
.contract-details {
  flex-grow: 1;
  margin-right: 20px;
}

/* Individual contract reference and date styling */
.contract-ref,
.contract-date {
  margin: 0;
  font-size: 1rem;
  color: #555555;
}

/* Download link styling */
.download-link {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  background-color: #f1f1f1;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect on download link */
.download-link:hover {
  background-color: #007bff;
  color: #ffffff;
}

/* Loading spinner and message container */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

/* Spinner styling */
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 123, 255, 0.3);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}







































.copy-button {
  background-color: #F4F6FA; /* Set background color */
  border: 1px solid #d1d9e6; /* Light border to give a subtle definition */
  color: #007bff; /* Button icon color */
  border-radius: 5px; /* Rounded corners for a softer look */
  padding: 6px 10px; /* Padding for better spacing */
  cursor: pointer; /* Pointer cursor to indicate it's clickable */
  font-size: 1rem; /* Font size for the icon */
  display: inline-flex; /* Align icon properly */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
}

.copy-button:hover {
  background-color: #e2e8f0; /* Slightly darker background on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Add a subtle shadow on hover */
}

.copy-button:active {
  border-color: #a7b5cc; /* Darken border even more on press */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2); /* Inset shadow to mimic press effect */
}

.copy-button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); /* Custom focus outline */
}
