.rowcall {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
}


.TitlContwhab {
  align-items: flex-start;

  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
}

.forhead {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

}

.TitlContKhafet {

  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
}

.blueNewGREEN {
  padding: 12px;
  width: 150px;
  background-image: linear-gradient(to right, #416BF6 0%, #416BF6 50%, #416BF6 100%);
  color: #F4F6FA;
  font-size: 16px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px #0000004d;
}

.blueNewGREEN:active {
  background-color: #416BF6;
  color: #F4F6FA;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.blueNewGREEN p {
  color: #F4F6FA;
  font-size: x-small;
}

@media (max-width: 600px) {
  .blueNewGREEN {
    width: 150px;
    font-size: 14px;
  }

  .blueNewGREEN p {
    font-size: small;
  }
}

@media (max-width: 400px) {
  .blueNewGREEN {
    width: 140px;
    padding: 8px;
    font-size: 12px;
  }

  .blueNewGREEN p {
    font-size: smaller;
  }
}


@media (max-width: 600px) {
  .blueNewGREEN {
    width: 120px;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .blueNewGREEN {
    width: 100px;
    padding: 8px;
    font-size: 12px;
  }
}

.capital {
  font-weight: bold;
  font-size: larger;
}

.hanaback img {
  max-width: 15%;
  height: auto;
  order: 2;
}

.hanaback {
  background-color: #F4F6FA;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;
}

.hanaback p {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  order: 1;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {

  .TitlContKhafet {
    font-size: 20px;

    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
  }

  .rowcall {
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .TitlContwhab {
    align-items: flex-start;

    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
  }

  .rowcall>* {
    margin-bottom: 15px;
  }
}


.aligne {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

}



.search-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: min-content;
}

.textContainer {
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.rowcall select {
  width: 100%;
  height: 60px;

}

.rowcall button {
  height: 100%;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 16px;
  box-sizing: border-box;
}

.aligne {
  margin-left: 10px;
}

.red {
  background-color: red;
}

button {
  padding: 10px 15px;
  font-size: 16px;
  flex: 1 1 auto;
}

.error-message {
  color: red;
  font-size: 14px;
}

.mobileDes {
  display: none
}



.bekwhiteblue:hover {
  background-color: #eff3fe;
  color: #002677;
}


.bekwhiteblue {

  border: 2px solid #416bf6;
  box-shadow: none;
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: #002677;
  background-color: #ffffff;

}



@media (max-width: 768px) {

  .rowcall select,
  .rowcall button {
    font-size: 14px;
  }

  .shusar {
    width: 0px;
  }

  button {
    padding: 8px 12px;
    font-size: 14px;
  }
}


.desktop {
  padding-right: 7%;
  padding-left: 7%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #F4F6FA;

  height: 340px;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/network.png?alt=media&token=d34736f9-3fb8-4533-a3d8-590711cd5b3f');
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.cardsakhif {
  margin: 10px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
}

.cardsContainerma {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
}

@media (max-width: 600px) {
  .TitlContwhab {

    align-items: flex-start;

    font-size: 26px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
  }

  .blueNewTkhile {
    width: 150px;
    font-size: 14px;
  }

  .shusar {
    width: 0px;
  }

  .blueNewTkhile p {
    font-size: small;
  }
}

@media (max-width: 400px) {
  .blueNewTkhile {
    width: 140px;
    padding: 8px;
    font-size: 12px;
  }

  .blueNewTkhile p {
    font-size: smaller;
  }
}


@media (max-width: 600px) {
  .blueNewTkhile {
    width: 120px;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .blueNewTkhile {
    width: 100px;
    padding: 8px;
    font-size: 12px;
  }
}

.card-actions {
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0;

}




.blueNewTkhile {
  padding-top: 10px;
  width: 170px;
  max-width: 200px;
  background-image: linear-gradient(to right, #416BF6 0%, #416BF6 50%, #416BF6 100%);
  color: #F4F6FA;
  font-size: 12px;
  border: none;
  border-radius: 0px;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px #0000004d;
}

.blueNewTkhile:hover,
.blueNewTkhile:focus {
  background-image: linear-gradient(to right, #007BFF, #007BFF);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.blueNewTkhile:active {
  background-color: #007BFF;
  color: #F4F6FA;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.blueNewTkhile p {
  color: #F4F6FA;
  font-size: x-small;
}


@media (max-width: 768px) {

  .card-actions {
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
  }


  .dektopDes {
    display: none
  }

  .mobileDes {
    display: block
  }

  .form-group {
    flex: 1 1 100%;
  }

  .form-group {
    flex: 1 1 100%;
    margin: 10px 0;
  }

  .omarbuttons {
    margin: 10px 0;

  }

  .row {
    flex-wrap: wrap;
    justify-content: flex-end;

  }

  .desktop {
    margin: 0 auto;
    width: 100%;
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 4%;
    height: min-content;
    border: 1px solid #416bf6;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
  }


  .mobileDes-card {
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
    width: 300px;
  }

  .mobileDes-image {
    width: 100%;
    height: auto;
  }

  .mobileDes-body {
    padding: 15px;
  }

  .mobileDes-title {
    margin-top: 0;
    color: #333;
  }

  .mobileDes-text {
    color: #666;
    margin-bottom: 10px;
  }

  .mobileDes-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .mobileDes-detailsBtn,
  .Button {
    padding: 8px 16px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .mobileDes-detailsBtn:hover,
  .Button:hover {
    background-color: #004494;
  }


}

.btnDetails {
  font-size: 12px;
  width: 150px !important;
  background-color: #416BF6;
  color: #ffffff;
  cursor: pointer;
  height: min-content;
  margin: 0 auto;
}

.btnDetails:hover {
  font-size: 12px;
  transition: all 0.3s ease;
  width: 90px !important;
  background-color: #015e1b;
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  height: min-content;
  margin: 0 auto;
}



.from_start {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.from_start p {
  margin: 0;
  padding: 0;
}

.from_startTa {
  justify-content: flex-end;
}

.from_startTa p {
  margin: 0;
  padding: 0;
}

.blueNewtGREEN {
  padding-left: 3px;
  padding-right: 3px;
  height: 70px;
  width: 100%;
  background-image: linear-gradient(to right, #416BF6 0%, #416BF6 50%, #416BF6 100%);
  color: #F4F6FA;
  font-size: 16px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px #0000004d;
}

.blueNewtGREEN:active {
  background-color: #416BF6;
  color: #F4F6FA;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.blueNewtGREEN p {
  color: #F4F6FA;
  font-size: x-small;
}


@media (max-width: 600px) {
  .blueNewtGREEN {
    width: 100%;
    font-size: 14px;
  }

  .blueNewtGREEN p {
    font-size: small;
  }

  .GREEN {
    width: 100% !important;
    background-color: #416BF6;
  }
}

@media (max-width: 400px) {
  .blueNewtGREEN {
    width: 100%;
    padding: 8px;
    font-size: 12px;
  }

  .blueNewtGREEN p {
    font-size: smaller;
  }

  .GREEN {
    width: 100% !important;
    background-color: #416BF6;
  }
}


@media (max-width: 600px) {
  .blueNewGREEN {
    width: 100%;
    font-size: 14px;
  }

  .GREEN {
    width: 100% !important;
    background-color: #416BF6;
  }
}



.GREEN {

  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  background-color: #416BF6;
}

.form-option {
  border: 1px solid #bfbfbf;
  height: 60px;
  margin: 0 auto 6px;
  border-radius: 5px;
}

.backwazb {
  background-color: white;
  padding-right: 25px;
  padding-left: 25px;
  width: 90%;

}




.custom-select-conte {
  position: relative;
  width: 100%;

}

.shusar {
  width: 16px;
}

/* .custom-select-conte select {.custom-select-conte select {.custom-select-conte select { */



.custom-select-conte select {
  width: 100%;
  padding: 10px 40px 10px 10px;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/location.png?alt=media&token=8351e21c-cf4a-4381-8789-69c5b0421a08');
  background-repeat: no-repeat;
  background-size: 40px;
  font-size: 15px;
  outline: none;
  cursor: pointer;


  background-position: right 10px center;
}

[dir="rtl"] .custom-select-conte select {
  width: 100%;
  background-position: left 10px center;
  padding: 10px 10px 10px 40px;
}









.custom-select-container {
  position: relative;
  width: 100%;

}

.custom-select-container select {
  width: 100%;
  padding: 10px 40px 10px 10px;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/type.png?alt=media&token=22f28c33-47e4-46d1-adce-10dd7d97a399');
  background-repeat: no-repeat;
  background-size: 40px;
  font-size: 15px;
  outline: none;
  cursor: pointer;


  background-position: right 10px center;
}

[dir="rtl"] .custom-select-container select {
  width: 100%;
  background-position: left 10px center;
  padding: 10px 10px 10px 40px;
}








.custom-select-idba {
  position: relative;
  width: 100%;

}

.custom-select-idba select {
  width: 100%;
  padding: 10px 40px 10px 10px;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/month.png?alt=media&token=91b02b10-3027-4b92-9d34-604469c8ecb4');
  background-repeat: no-repeat;
  background-size: 40px;
  font-size: 15px;
  outline: none;
  cursor: pointer;

  background-position: right 10px center;
}

[dir="rtl"] .custom-select-idba select {
  width: 100%;
  background-position: left 10px center;
  padding: 10px 10px 10px 40px;
}















@media (max-width: 600px) {


  .blfuewTkhile {
    width: 100%;
    font-size: 14px;
  }



  .blfuewTkhile p {
    font-size: small;
  }
}

@media (max-width: 400px) {
  .blfuewTkhile {
    width: 100%;
    padding: 8px;
    font-size: 12px;
  }

  .blfuewTkhile p {
    font-size: smaller;
  }
}


@media (max-width: 600px) {
  .blfuewTkhile {
    width: 100%;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .blfuewTkhile {
    width: 100%;
    padding: 8px;
    font-size: 12px;
  }
}





.blfuewTkhile {
  padding: 12px;
  color: #F4F6FA;
  font-size: 16px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px #0000004d;
}

.blfuewTkhile:hover,
.blfuewTkhile:focus {
  background-image: linear-gradient(to right, #007BFF, #007BFF);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.blfuewTkhile:active {
  background-color: #007BFF;
  color: #F4F6FA;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.blfuewTkhile p {
  color: #F4F6FA;
  font-size: x-small;
}
















.imagehosk {
  position: relative;
  display: inline-block;
}

.imagehosk img {
  display: block;
  width: 100%;
  height: auto;
}

.small-icon {
  font-size: 12px;
}

.butrow {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding-left: 75%;
  padding-right: 5%;
}

.left-button {
  background-color: rgb(255, 255, 255);
  border: none;
  padding: 2px;
  width: 1px;
  height: 30px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.left-button.active {
  background-color: rgba(131, 129, 129, 0.418);
}

.right-button {
  background-color: rgb(255, 255, 255);
  border: none;
  padding: 2px;
  width: 1px;
  height: 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

}

.right-button.active {
  background-color: rgba(131, 129, 129, 0.418);
}

.right-button .fa-chevron-right,
.left-button .fa-chevron-left {
  color: rgb(0, 0, 0);
}

.right-button.active .fa-chevron-right,
.left-button.active .fa-chevron-left {
  color: rgb(255, 255, 255);
}


.servobile {
  width: 10px;
  height: auto;
  display: flex;

}

.smacon {
  font-size: 12px;
  /* You can adjust the icon size separately */
}

.sertit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  color: #416bf6;
  font-weight: bold;
}



.from_startbaba {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;


}

.fucsm {
  width: 12px;
  font-size: 12px;
  color: #000000fd;
}

.fuctsm {
  width: 12px;
  font-size: 12px;
  color: #f5000000;
}

.from_startbaba p {
  margin: 0;
  padding: 0;
}







.mumtaza {
  font-weight: bold !important;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.mumtaza p {
  font-weight: bold !important;
  margin: 0;
  padding: 0;
}




.from_startwar {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
}

















/* Table Container */
.table-container {
  margin: 20px auto;
  padding: 10px;
  max-width: 1000px;
  overflow-x: auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Table */
.dektopDes {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  text-align: center;
  border: 1px solid #ddd;
}

/* Table Header */
.dektopDes thead th {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 12px 15px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

/* Table Rows */
.dektopDes tbody tr {
  border-bottom: 1px solid #ddd;
}



.dektopDes tbody tr:hover {
  background-color: #f9f9f9;
  transition: background-color 0.3s ease-in-out;
}

/* Table Cells */
.dektopDes td {
  padding: 10px 15px;
  vertical-align: middle;
  font-size: 14px;
  text-align: center;
}

/* Image Styling */
.dektopDes td img {
  max-width: 80px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Button Styling */


.blueNewTkhile:hover {
  background-color: #f8f8f8;
}

/* Responsive Styling */
@media (max-width: 768px) {


  .blueNewTkhile {
    font-size: 12px;
    padding: 6px 10px;
  }
}
