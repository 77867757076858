/* Home Container */
.home-containerwak {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.greenText {
  color: green;
  font-weight: bold; 
}

/* User Card */
.user-card {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Button Styles */
.unsubscribe-button, .change-password-button {
  color: #ffffff;
  margin: 10px 0;
}

.unsubscribe-button {
  background-color: #ff5555;
}

.change-password-button {
  background-color: #4285f4;
}

/* My Proposal Container */
.my-proposal-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.my-proposal-container h4 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* PDF List */
.pdf-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-item {
  margin: 10px 0;
}

.pdf-item a {
  text-decoration: none;
  color: #4285f4;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
   .user-card, .my-proposal-container {
    width: 100%;
    padding: 15px;
  }
  .home-containerwak {
    width: 95%;
    margin: 5px auto;
    padding: 10px;
  }
}

/* Icon and User Name Section */
.icon-user-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.icon-user-container .user-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Navigation Container */
.navigation-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
}

.navigation-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.navigation-item span {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
}

.emergency-call-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.emergency-call-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #ff5555;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.emergency-call-button:hover {
  background-color: #ff3333;
}
