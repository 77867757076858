.solsec {
    padding: 20px;
 
    margin: 50px auto;
}
.contact-us-solution {
    margin: 0;
    padding-top: 5%;
    background-color: #f4f4f4;
    color: black;
  }.solsec {
    padding: 20px;
    margin: 50px auto;
}

.contact-us-solution {
    margin: 0;
    padding-top: 5%;
    background-color: #f4f4f4;
    color: black;
}

.background-Sol {
    background-image: url('images/blueback.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 40vh;
}
.solution-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.solution-button {
  margin: 0 10px;
  padding: 15px 30px;
  font-size: 18px;
  background-color: #b6b6b6; /* Update to your desired color */
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.solution-button:hover {
  color: #297fb9;
  background-color: #686868; /* Change color on hover if desired */
  transform: scale(1.05);
}


/* Add specific styles for each button */
 
  .background-Sol {
    background-image: url('images/blueback.jpg');
    background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     height: 40vh;
 } 