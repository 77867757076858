header {
  text-align: center;
}

/* Main Container */
.restricted-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #F4F6FA;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  height: 80%;
}

.title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Message Styling */
.message {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

/* Contact Information Styling */
.contact-info,
.email-info,
.address {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.5;
}

.contact-info strong,
.email-info strong,
.address strong {
  color: #0056B3;
}

.address {
  margin-bottom: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .restricted-page {
    padding: 15px;
  }
  .restricted-page {

    height: 400px;
  }
  .title {
    font-size: 1.5rem;
  }

  .message {
    font-size: 1rem;
  }

  .contact-info,
  .email-info,
  .address {
    font-size: 0.9rem;
  }

  .address {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .restricted-page {
    padding: 10px;
  }

  .title {
    font-size: 1.3rem;
  }

  .message {
    font-size: 0.9rem;
  }

  .contact-info,
  .email-info,
  .address {
    font-size: 0.8rem;
  }

  .address {
    margin-bottom: 15px;
  }
}
