.language-button-container {
  position: relative;
  display: inline-block;
}

.buttontr {
  padding: 0%;
  border-radius: 30px;
  background-color: #00bf63;
  margin-top: 24px;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
}


.navbar-logo {
  height: auto;
  width: 40px;
}



.navbar-logo {
  width: 50px;
  height: 50px;
}

.navbare {
  background-color: #f2f2f2;
  margin: auto;
  width: 100%;
  text-align: center;
  height: 80px;
  margin-top: 30px;
}

@media (max-width: 700px) {

  .navbare {
    height: 58px;
  }

  .navbar-logo {
    width: 30px;
    height: 30px;
  }
}

.moreCloser {
  margin-bottom: 12px;
  padding-left: 50px;
  padding-right: 50px;
}

.redcoffe {
  color: rgb(255, 255, 255) !important;
  font-size: 14 !important;
  font-weight: normal !important;


}

.moreCloser li {
  padding: 0.1rem;
  margin: 0;
}

.moreCloser {
  margin: 0 0.1rem;
  padding: 0.5rem;
}

.triabel {
  background-color: #072C7B;
  ;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
}

@media (max-width: 600px) {
  .triabel {
    top: 1px;
    left: 10px;
    font-size: 8px;
    padding: 3px 3px;
    min-height: 15px;

  }
}

.language-dropdown {
  padding: 8px;
  font-size: 16px;
  border-radius: 0px;
  border: 1px solid #cccccc00;
  background-color: #000000FF;
  color: white;
}


.language-dropdown option {
  background-color: white;
  color: #072C7B;
}

.language-dropdown option:before {
  margin-right: 8px;
}









.dropdown {
  height: 100%;
  position: relative;
  display: inline-block;
  z-index: 1000;
  padding-top: 12.5%;
}

.dropdown-btn {
  background-color: #FF000000;
  color: black;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px;
  width: 120px;
  font-weight: bold;
}

.dropdown-btn .flag {
  margin-right: 8px;
  font-size: 16px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  min-width: 120px;
  z-index: 1001;
  padding: 0;
  border-radius: 5px;
  top: 70px;
  left: 0;
}

.dropdown-menu :hover {
  color: #428bca;
}

.dropdownitem {
  padding: 10px 12px;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
}



.dropdownitem .flag {
  margin-right: 8px;
  font-size: 16px;
}

.dropdown-btn:after {
  content: '';
  border: solid rgba(255, 255, 255, 0);
  border-width: 0 4px 4px 4px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.dropdown-btn[aria-expanded="true"]:after {
  border-width: 4px 4px 0 4px;
}

.dropdown-menu:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 20px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
  z-index: 10;
}


.dropdown-btn:hover {
  border-bottom: 1px solid white;

}


/* #428bca */










.postthing {
  align-items: center;
  flex: auto;
  padding-top: 70px;
  padding-left: 100px;
  background-image: none !important;
  color: black;
}

@media (max-width: 768px) {
  .dropdown-btn {
    color: aliceblue;
  }
}