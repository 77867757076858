.PersonalInfoForm {
  background-color: #F4F6FA;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 120%;
  max-width: 500px;
  box-sizing: border-box;
  margin: 20px auto;
}

.PersonalInfoForm1 {
  color: #072C7B;
  border-bottom: 1px solid #007BFF;
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 18px;
}

.sostitel {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-group label {
  margin-right: 10px;
  white-space: nowrap;
  min-width: 100px;
}

.PersonalInfoForm3 {
  margin-top: 5px;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.PersonalInfoForm3[type="radio"],
.PersonalInfoForm3[type="checkbox"] {
  width: auto;
  margin-right: 5px;
  margin-left: 5px;
}

.PersonalInfoForm3[type="radio"]+label,
.PersonalInfoForm3[type="checkbox"]+label {
  margin-right: 15px;
}










.ker5 {
  font-size: 1em;
  margin-right: 0.5em;
}

.ker3 input[type="radio"],
.ker3 input[type="checkbox"] {
  margin-left: 0.5em;
}

.ker4 input[type="radio"],
.ker4 input[type="checkbox"] {
  margin-left: 0.5em;
}

.tzbit {
  padding-bottom: 40px;
}



.ker-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ker-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.ker-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .PersonalInfoForm {
    width: 90%;
    margin: 10px auto;
    padding: 15px;
  }

  .PersonalInfoForm1 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .sostitel {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .PersonalInfoForm3 {
    font-size: 12px;
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .PersonalInfoForm {
    width: 95%;
    margin: 5px auto;
    padding: 10px;
  }

  .PersonalInfoForm1 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .sostitel {
    font-size: 10px;
    margin-bottom: 6px;
  }

  .PersonalInfoForm3 {
    font-size: 10px;
    padding: 4px;
  }
}

.containersyehiye {
  display: flex;
  flex-direction: row-reverse; /* Reverses the row direction */
}


.adou {
  width: 150px;
  background-image: linear-gradient(to right, #0741A2 0%, #0056B3 50%, #005DB8 100%);
  padding: 10px 20px;
  color: white;
  font-family: 'Tajawal-Regular';
  font-size: 16px;
  text-align: center;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
}

.adou:hover,
.adou:focus {
  background-image: linear-gradient(to right, #0056B3, #0741A2);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.adou:active {
  background-color: #072C7B;
  color: #FFFFFF;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.modal-content input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.modal-content button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-content button:first-of-type {
  background-color: #072C7B;
  color: white;
}

.modal-content button:first-of-type:hover {
  background-color:#072c7a;
}

.modal-content button:last-of-type {
  background-color: #dc3545;
  color: white;
}

.modal-content button:last-of-type:hover {
  background-color: #c82333;
}
