.blueNewTkhi:disabled {
  background-color: #cccccc; /* Disabled background color */
  color: #666666; /* Disabled text color */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  box-shadow: none; /* Remove box-shadow when disabled */
}

.blueNewTkhi {
  padding: 12px;
  width: 150px;
  background-image: linear-gradient(to right, #007BFF 0%, #007BFF 50%, #007BFF 100%);
  color: #F4F6FA;
  font-size: 16px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px #0000004d;
}

.blueNewTkhi:hover,
.blueNewTkhi:focus {
  background-image: linear-gradient(to right, #007BFF, #007BFF);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.blueNewTkhi:active {
  background-color: #007BFF;
  color: #F4F6FA;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.blueNewTkhi p {
  color: #F4F6FA;
  font-size: x-small;
}

/* Responsive styles */
@media (max-width: 600px) {
  .blueNewTkhi {
    width: 100px;
    padding: 10px;
    font-size: 14px;
  }

  .blueNewTkhi p {
    font-size: small;
  }
}

@media (max-width: 400px) {
  .blueNewTkhi {
    width: 80px;
    padding: 8px;
    font-size: 12px;
  }

  .blueNewTkhi p {
    font-size: smaller;
  }
}

.calendar7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
}
.calendar8 {
  display: flex; 
  /* Aligns the elements in a row */
  justify-content: center;
  /* Centers the content horizontally */
  align-items: center;
  /* Centers the content vertically */
  gap: 10px;
  /* Adds space between the elements */
}
.calendar12 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* Creates a grid with 7 columns */
  grid-gap: 9px;
  justify-content: center;
  /* Centers the grid itself */
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
}

.calendar15 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  height: 42px;
  width: 42px;
  cursor: pointer;
  color: #28A745;
  /* Beige color for the day numbers */
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin: auto;
  /* Ensures day numbers are centered within their grid cells */
}

.disabled-day {
  color: #DC3545;
  pointer-events: none;
  opacity: 0.5;
}

.friday-day {
  color: #DC3545;
  /* Red color for Fridays */
}

.calendar17 .calendar18 {
  background: #f2c94c;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: none;
}

.calendar17 .calendar24 .calendar18 {
  display: block;
}

.calendar15.calendar24::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.24),
    0px 0px 7px rgba(0, 0, 0, 0.12);
}

/* Responsive Styles */
@media (max-width: 600px) {
  .calendar7 {
    flex-direction: column;
    gap: 10px;
  }

  .calendar12 {
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 6px;
  }

  .calendar15 {
    height: 36px;
    width: 36px;
  }

  .calendar9 {
    font-size: 1.2rem;
  }
}

.calendar10,
.calendar11 {
  width: 16px;
  /* Set the width to a smaller value */
  height: 16px;
  /* Set the height to match the width */
  cursor: pointer;
  /* Keeps the pointer cursor on hover */
}

@media (max-width: 600px) {

  .calendar10,
  .calendar11 {
    width: 12px;
    /* Further reduce size on smaller screens */
    height: 12px;
  }
}

.calendar-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px;
}

.calendar-header-item {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  color: #6c757d;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 9px;
}

.selected-time {
  background-color: #e0e0e0;
  /* تمييز الفتحة الزمنية المحددة */
  border-radius: 5px;
}

.calendar32.selected {
  font-weight: bold;
  color: #007BFF;
  /* تغيير لون الوقت المحدد في InfoTab */
}

.selected-time-display {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: block;
}

.calendar18 input[type="checkbox"] {
  display: inline-block;
  margin-right: 10px;
  width: auto;
  height: auto;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  background-color: white;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;
  color: #007bff;
  transition: background-color 0.2s ease;
}

.custom-checkbox.checked {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.custom-checkbox:hover {
  background-color: #e6e6e6;
}


.calendar15.selected-day {
  background-color: #007BFF; /* Change background color to indicate selection */
  color: white; /* Change text color to white for contrast */
  border: 2px solid #0056b3; /* Add a border to make it more distinct */
  box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.5); /* Add a subtle shadow */
}

.confirm-selection-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28A745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; 
}

.confirm-selection-button:hover {
  background-color: #218838;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
