.filter-dropdown-mobile {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: top 0.4s ease-out;
    z-index: 10;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 0;
}
.FiltreActivateBanner {
    font-size: 1.2rem;
    color: #333;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
  }
  
.filter-dropdown-mobile.visible {
    max-height: calc(100vh - 50px); /* Adjust height based on your layout */
    overflow-y: auto;
    top: 0;
}

.filter-dropdown-mobile:not(.visible) {
    height: 0;
    padding: 0;
    margin: 0;
}


.close-filter-btn {
    display: block;
    margin-top: 20px;
    /* padding: 12px 20px; */
    padding-right: 45%;
    padding-left: 45%;
    background-color: #072C7B;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.close-filter-btn:hover {
    background-color: #0056b3;
}

.filter-btn-desktop {
    display: block;
    background-color: #072C7B00;
    color: #008e28;
    border: none;
    border-radius: 8px;
    font-size: 32px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}










.filter-btn-desktop:hover {
    transform: scale(1.05);
}

@media (max-width: 767px) {
    .filter-btn-desktop {
        display: none;
    }
}

.filter-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.filter-row label {
    font-size: 16px;
    font-weight: bold;
}

.rowcheck {
    display: flex;
    justify-content: flex-end;
}

.checkbox-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    background-color: #f4f6faab;
    width: 100%;
}


@media (max-width: 767px) {
    .filter-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .checkbox-group {
        width: 100%;
        background-color: #f4f6faab;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media (min-width: 768px) {
    .filter-row {
        display: flex;
        flex-direction: row;
    }

    .checkbox-group {
        background-color: #f4f6faab;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
}

/* Style for Desktop Filter Dropdown */
.filterdropdowndesktop {
    right: 0;
    top: 100px;
    /* Adjust this to fit your header or page layout */
    width: 300px;
    /* Adjust width as necessary */
    height: calc(500vh - 100px);
    /* Full height minus top offset */
    background-color: white;
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
    z-index: 20;
    padding: 20px;
    overflow-y: auto;
    /* Enable scrolling if content overflows */
}

@media (max-width: 767px) {
    .filterdropdowndesktop {
        display: none;
    }
}























/* Container styles for desktop layout */
.contmain {

    display: flex;
    flex-direction: row;
    width: 100%;
}

.columnpolicy {background-color: white;
    flex-direction: column;
}

.cont2 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;

}

/* .speed2 {
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
    background-color: #f4f6fa;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
} */

.speed2 {
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
    background-color: #f4f6fa;
    padding: 0px !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
}

.speed2:hover {
    transform: scale(1.05);
    /* Slight hover effect */
}

.speed3 {
    width: 100%;
    height: auto;
    max-width: 200px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.speed4 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.speed5 {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}

/* Mobile layout styles */
@media only screen and (max-width: 768px) {
    .cont1 {
        display: none;
    }

    .contmain {
        flex-direction: column;
    }

    .cont2 {
        display: block;
    }

    .speed2 {
        flex: 1 1 100%;
        /* Each item takes up full width on mobile */
        margin-bottom: 20px;
    }

    .speed3 {
        max-width: 100%;
        /* Allow image to take full width on mobile */
    }

    .speed4 {
        font-size: 16px;
        /* Adjust font size for mobile */
    }

    .speed5 {
        font-size: 12px;
        /* Adjust font size for mobile */
    }
}

/* Tablet layout styles */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .speed2 {
        flex: 1 1 calc(50% - 20px);
        /* Display two items per row on tablets */
    }
}

/* Larger screens */
@media only screen and (min-width: 1025px) {
    .speed2 {
        flex: 1 1 calc(33.333% - 20px);
        /* Display three items per row on desktop */
    }
}

/* Container for desktop view */
.desktop-view {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 items per row */
    gap: 20px;
    /* Space between grid items */
    width: 100%;
}

.speed2desktop {
    background-color: #f4f6fa;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
}

.speed2desktop:hover {
    transform: scale(1.05);
}

.speed3desktop {
    width: 100%;
    height: auto;
    max-width: 200px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.speed4desktop {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.speed5desktop {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}

@media only screen and (max-width: 768px) {
    .desktop-view {
        grid-template-columns: 1fr;
        /* 1 item per row on mobile */
    }

    .speed2desktop {
        margin-bottom: 20px;
    }

    .speed4desktop {
        font-size: 16px;
    }

    .speed5desktop {
        font-size: 12px;
        /* Adjust font size for mobile */
    }
}

/* Tablet layout styles */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .desktop-view {
        grid-template-columns: repeat(2, 1fr);
    }
}

.filter-label {
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
}

.active-label {
    color: #007bff;
    font-weight: bold;
    height: 100%;
}




  
  .filter-label-row {
    display: flex;
    justify-content: flex-start;
  }
  
  .checkbox-group-row {
    display: flex;
    flex-wrap: wrap; 
    gap: 15px; 
  }
  