
.filter-btn-women {
    display: block;
}



@media (min-width: 768px) {




    .filter-btn-women {
        display: none;
    }



}
.Filter_Icon {
    width: 32px; /* Icon size */
    height: 32px; 
    background-color: white; /* White background */
    border: 2px solid #ccc; /* Optional border for a polished look */
    border-radius: 50%; /* Makes the background a circle */
    display: flex; /* Ensures the icon is centered */
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adds depth with a shadow */
    box-sizing: border-box; /* Includes padding and border in the size */
}


.filter-btn-women {
    background-color: #FFC0CB;
    color: #ffffff;
    border: none;
    border-bottom-left-radius: 28px;
    border-top-left-radius: 28px;
    ;
    cursor: pointer;
    font-size: 32px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: fixed;
    top: 350px;
    right: -10px;
    z-index: 9;
}


.filter-btn-women:hover {
    background-color: #008e28a6;
    transform: scale(1.03);
}



/* Hide mobile filter button on desktop */
@media (min-width: 768px) {
    .filter-btn-women {
        display: none;
    }


}


