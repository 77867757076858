.bread {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.textwhite {
  color: #F4F6FA;
  font-weight: bold;
}

@font-face {
  font-family: 'Tajawal-Regular';
  src: url('../fonts/Tajawal-Regular.ttf') format('truetype');
}

body {
  font-family: 'Tajawal-Regular', sans-serif;
}

.edgardo:hover {
  transform: translateY(-5px);
}

.edgardo img {
  object-fit: cover;
}

.textont {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
}

.articleContainer {
  display: contents;
}

.small {
  font-size: 14px;
  color: #000000;
}

.articleTitle {
  font-size: 24px;
}

.articleTitle p {
  font-weight: bold;
  color: #000000;
}



.artt {
  font-weight: bold;
}

.articleContent p {
  color: #000000;
  text-align: justify;
}

.smalle {
  width: 1px;
  height: 1px;
  background-color: transparent;
}

@media (max-width: 600px) {
  .articleContent p {
    width: 100%;
  }

  .articleContent {
    width: 100%;
  }

  .smalle {
    height: max-content;
    width: auto;
  }

  .small {
    font-size: 16px;
  }

  .textont {
    font-size: 16px;
  }

  .articleTitle {
    font-size: 22px;
    width: 100%;
  }
}

#ContactU,
#addservice,
#ourdata,
#gides {
  max-width: 1200px;
  margin: auto;
  padding-top: 1px;
}

.line {
  height: 1px;
  /* or however thick you want the line */
  background-color: rgba(0, 0, 0, 0.1);
  /* Choose a color that fits the design */
  width: 100%;
  /* Line spans the full width of the container */
  margin-top: 20px;
  /* Adjust spacing as needed */

}


.SocialM {
  background-color: #F4F6FA
}

.centre {
  display: flex;
  flex-direction: column;
  /* Aligns children vertically */
  align-items: center;
  /* Centers children horizontally */
  justify-content: center;
  /* Centers children vertically */
  text-align: center;
  /* Ensures the text within is also centered */
}

.centred {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #F4F6FA;
  width: 80%;
}





























.banner {
  background-size: cover;
  background-position: center;
  height: 500px;
}



[dir="rtl"] .button-collapses {
  flex-direction: row-reverse;
  background-color: #ffffff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  text-align: start;
  text-align: right;

}

[dir="ltr"] .button-collapses {
  text-align: left;
  background-color: #ffffff;
  /* background-color: #F4F6FA; */
  text-align: end;
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;

}

/* Ensure text alignment and other properties flip appropriately */
[dir="rtl"] .textCont,
[dir="rtl"] .titlenormalcolapsTextUL {
  text-align: right;
}

[dir="ltr"] .textCont,
[dir="ltr"] .titlenormalcolapsTextUL {
  text-align: left;
}















.faq-item {
  width: 70%;
  margin: 0 auto;
}





















.TitlCont {
  font-size: 32px;
  color: #ffffff;
  font-weight: bold;
}

.TitlContw {
  font-size: 32px;
  color: #000000;
  font-weight: bold;
}

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  padding-bottom: 110px;
  color: #ffffff;
}

.breadcrumb p {
  margin: 0 10px;
  cursor: pointer;
  font-size: 18px;
  color: #ffffff;

}

.breadcrumb p:not(:last-child)::after {
  content: " > ";
  margin-left: 5px;
  margin-right: 5px;
}
 

.TitleCont {
  font-size: 24px;
  color: #000000;
  font-weight: bold;
}

.textBCont {
  font-size: 16px;
  color: #000000;
}

.textCont {
  color: #333;
  font-weight: bold;
  font-size: medium;
}

.textWCont {
  font-size: 16px;
  color: #F4F6FA;
}


.button-collapses:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);
  /* Thin line-like shadow at the bottom */

}

.button-collapses:active {
  background-color: #F4F6FA;
  /* Change to your desired color */
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);
  /* Thin line-like shadow at the bottom */

}

.button-control {
  margin: 10px;
  padding: 10px;
  background-color: blue;
  border: none;
  cursor: pointer;
}



.bannerText { 

  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 1.5); */
}

.bannerText span {
  display: block;
  margin-bottom: 10px;
  /* Adjust spacing between text */
}




.GoldCont {
  font-size: 38px;
  color: #000000;
  font-weight: bold;

}


@media (max-width: 600px) {
  .GoldCont {
    font-size: 28px;
    color: #000000;
    font-weight: bold;
  
  }
  .TitlCont {
    font-size: 28px;
    color: #F4F6FA;
    font-weight: bold;
  }

  .breadcrumb {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 105px;

  }

  .breadcrumb p {
    white-space: normal;
    flex-basis: 100%;
    text-align: center;
  }

 

  .TitleCont {
    font-size: 22px;
    color: #000000;
    font-weight: bold;
  }

  .textCont {
    color: #333;
    font-weight: bold;
    font-size: medium;
  }

  .textWCont {
    font-size: 18px;
    color: #F4F6FA;
  }

  .textBCont {
    font-size: 16px;
    color: #000000;
  }

  .cardContactU {
    width: 80% !important;
    align-self: center;
    /* Center the individual card horizontally */
  }

  .cardsContactU {
    flex-direction: column !important;
    justify-content: center;
    /* Center the cards horizontally */
  }

  .faq-item {
    width: 90%;
    margin: 0 auto;
  }

}



.ContactU {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardsContactU {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.cardContactU {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;


}

.cardContactU:hover {
  opacity: 0.8;
}

.cardContactU img {
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}







.sendbtn {
  font-family: 'Tajawal-Regular';
  background-color: #fff;
  border: 1px solid #0056B3;
  color: #072C7B;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 30px;
}

.sendbtn:hover {
  background-color: #F4F6FA;
  color: #072C7B;
}

.sendbtn p {
  font-family: 'Tajawal-Regular';
}


.socialIcons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.socialIcons a {
  color: #072C7B;
  font-size: 400%;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s, transform 0.3s;
}

.socialIcons a:hover {
  color: #072C7B8f;
  transform: scale(1.1);
}

.Blueback {
  background-color: #072C7B;

}




.subscribe-button {
  background-color: #0056B3;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.subscribe-button:hover {
  background-color: #003E80;
}

.button-collapses span {
  display: inline-block;
  transition: transform 0.3s ease;
}


.titlenormalcolapsTextUL {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.faq-item.open .titlenormalcolapsTextUL {
  max-height: 1000px;
}

.TitleConta {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.noufWater {
  font-size: 16px;
  color: #072C7B;
}



















.bannersol {
  background-size: cover;
  background-position: center;
  height: 320px;
  position: relative;
  text-align: center;
}

.nofe1 {
  color: #333;
  background-color: #fff;
  padding: 20px;
  line-height: 1.6;
}

.bluenof {
  font-size: 20px;
  color: #000000;
  margin-bottom: 10px;
}

.nofe2 {
  font-size: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.nofe3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.nofe4 {
  margin-bottom: 10px;
}

.nofe5 {
  font-size: 16px;
  color: #0056B3;
}





























body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}



ul,
ol,
li,
dl,
dt,
dd {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

legend {
  color: #000;
  padding: 0;
}

a img,
iframe {
  border: none;
}





body {
  color: #55555F;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}



li {
  color: #55555F;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-weight: 400;
  line-height: 1;
  color: #666;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
  font-size: 65%;
}

h1 strong,
h2 strong,
h3 strong {
  color: #002677;
}

h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-size: 75%;
}

h4 strong,
h5 strong,
h6 strong {
  color: #005bac;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background-color: #f5f5f5;
  padding: 20px 0;
}

.main {
  padding: 20px 0;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
}

/* Additional styles */
/* ... */

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul,
ol,
li,
dl,
dt,
dd {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

legend {
  color: #000;
  padding: 0;
}

a img,
iframe {
  border: none;
}



.newsletter-section {
  background-color: #0056B3;
  padding: 40px;
  text-align: center;
  color: #fff;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #072C7B;
  border-radius: 30px;

}















.buttonpse {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f4f6fa;
  width: 80%;
  margin: auto;
}

 

[dir="rtl"] .buttonpse,
[dir="ltr"] .buttonpse {
  flex-direction: row;
}

.buttonpse button {
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonpse button:hover {
  background-color: #003e80;
}

.subTitleCont {
  color: #000000;
  font-weight: bold;

  margin-right: 10px;
  font-size: 22px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 80%;
}


.downan {
  padding-top: 10%px;
}


.downan {
  background-color: #0056B3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.downan:hover {
  background-color: #0057b38c;
}




.button-collapsess span {
  display: inline-block;
  transition: transform 0.3s ease;
}

.button-collapsess:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}

.button-collapsess:active {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}

.button-collapsess:hover {
  background-color: #F4F6FA;
}

[dir="rtl"] .button-collapsess {
  flex-direction: row-reverse;
  background-color: #ffffff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  text-align: start;
  text-align: right;

}

[dir="ltr"] .button-collapsess {
  text-align: left;
  background-color: #ffffff;
  text-align: end;
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;

}

.whitoo {
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}