.why {
     background-color: #ffffff;

}

@font-face {
    font-family: 'CustomFont';
    src: url('../fonts/Tajawal-Regular.ttf') format('truetype');
}

body {
    font-family: 'CustomFont', sans-serif;
}

.whytitel {
    color: rgb(0, 0, 0);
    font-size: 24px;
    margin-bottom: 20px;
}

.cardss-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 10px;
    justify-content: center;
    align-content: center;
}

.cards {
    flex-direction: column;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    align-items: center;
}

.cards:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cards-text {
    color: #000000;
    font-size: 16px;
    margin-bottom: 10px;
}

.cards-title {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
}

.cards-body {
    font-size: 16px;
}

.cards-img{
    max-width: 35px;
    height: auto;
}

.QuestionD {
    background-color: #072C7B;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.titleQuestionD {
    padding-right: 20px;
    font-size: 26px;
}

.txtQuestionD {
    padding-right: 20px;
    font-size: 26px;
    color: #F4F6FA;
}

.btnpart3 {
    background-color: #072C7B;
    border: 2px solid #0056B3;
    color: #F4F6FA;
    padding: 8px 16px;
    /* Adjust padding as needed */
    cursor: pointer;
    font-size: 16px;
}

.btnpart3:hover {
    background-color: #F4F6FA;
    color: #072C7B;
}



.part1,
.part2,
.part3 {
    padding: 20px;
    border-radius: 4px;
    margin: 5px;
    text-align: center;
}

@media (min-width: 600px) {
    .cardss-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .cards {
        flex-direction: column;
        align-items: center;
    }
    .cards-body {
        font-size: 18px;
    }
    .cards-text {
        flex: 1;
        margin-right: 20px;

    }
    .whytitel {
        color: rgb(0, 0, 0);
        font-size: 22px;
        margin-bottom: 20px;
    }
    .cards-img img {
        max-width: 25px;
        height: auto;
    }
    .titleQuestionD {
        padding-right: 20px;
        font-size: 16px;
    }
    
}











.ArselLanaTalabak {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    text-align: center;
    background-color: #f4f6fa;
    width: 80%;
    margin: auto;
  }
  
  .subTitleCont {
    margin-right: 10px;
  }
  
  [dir="rtl"] .ArselLanaTalabak,
  [dir="ltr"] .ArselLanaTalabak {
    flex-direction: row;
  }
  
  .ArselLanaTalabak button {
    border: 2px solid #416bf6;
    box-shadow: none;
    min-height: 40px;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1;
    padding: 13px 43px;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    color: #002677 ;  
     background-color:    #ffffff;
     transition: background-color 0.3s;
  }
  
  .ArselLanaTalabak button:hover {
    background-color:    #fcf9f9;
}
  



.subTitleContfak11 {
    color: #000;
    font-size: 24px;
  }
  