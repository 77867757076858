.partners-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns on larger screens */
    gap: 20px; /* Space between items */
    padding: 20px;
}

.partner-item {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
    text-align: center; /* Centers the content within each item */
}

.partner-item img {
    max-width: 100%;
    height: auto;
}

.partner-item p {
    margin: 5px 0;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .partners-container {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    }
}

/* Media query for very small mobile devices */
@media (max-width: 480px) {
    .partners-container {
        grid-template-columns: repeat(1, 1fr); /* 1 column on very small screens */
    }
}
