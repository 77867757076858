.whiteback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #ffffff 0%, #f7f7f7 100%);
}
.whiteloading{
  color: white;
}
.splash-gif {
  width: 100%;
  max-width: 600px;
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



.App {
  text-align: center;
}



body {
  background-color: #072C7B;
  color: black;
  margin: 0;
  padding: 20px;
  font-family: 'Tajawal-Regular', sans-serif;
  padding: 10%;

}

header {
  text-align: center;
}

label {
  display: block;
  margin: 15px 0 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Tajawal-Regular';
  src: url('./fonts/Tajawal-Regular.ttf') format('truetype');
}


.App-header {
  /* background-color: #072C7B; */
  background-color: #072C7B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}






.fixed-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.fixed-button button {
  padding: 15px;
  font-size: 18px;
  background-color: #008e28;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.fixed-button button:hover {
  background-color: #297fb9;

  color: #fdc333;

}


.home-container {
  padding: 20px;
}



.modal-content {
  background: #fff;
  /* White background for modal content */
  padding: 20px;
  border-radius: 8px;
}

.logout-button,
.unsubscribe-button,
.change-password-button {
  margin-right: 10px;
}

/* Icon Styling */
.icon {
  font-size: 20px;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .home-container {
    padding: 10px;
  }

  .modal-content {
    padding: 10px;
  }
}


.footerFontWhite{
  color: #fff;
}



















@media (max-width: 1200px) {
  body {
    padding: 8%; 
  }
}

@media (max-width: 768px) {
  body {
    padding: 5%; 
  }
}

@media (max-width: 576px) {
  body {
    padding: 2%;
  }
}

@media (max-width: 360px) {
  body {
    padding: 1%; 
  }
}