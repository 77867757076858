/* .services-container {
  position: relative; 
  width: 100%; 
  height: 100vh; 
  overflow: hidden; 
}

.services-background-base {
  position: absolute;
  z-index: 0; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./components/images/78d165eb8db050f5d1a305ed1e6b9efe.WebP');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.services-background-overlay {
  position: absolute;
  z-index: 1; 
  top: 210px;  
  left: 0px;
  width: 660px;  
  height: 742.8px;  
  transform: rotate(-180deg); 
  opacity: 1;  
}
@media (max-width: 768px) {
  .services-background-overlay {
    display: none;  
  }
} */








.padingtop {
  padding-top: 5px;
}
.showMoreS {
  cursor: pointer;
  color: #007bff; /* Blue color for text button */
  font-weight: bold;
  margin-left: 5px;
  text-decoration: underline; /* Optional for a link-like appearance */
}
.showMoreS:hover {
  text-decoration: none; /* Removes underline on hover for a cleaner effect */
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.TitlrrContw {
  font-size: 20px;
  color: #333;
}

.TitlContw {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.red {
  background-color: red;
}

.app-container {
  background-color: #072C7B;
  display: flex;
  flex-direction: column;
}

.bannernaksol {
  position: absolute;
  max-height: min-content;
}

.bluebody {
  background-color: #072C7B;
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  padding-right: 15%;
  border-radius: 10px;
}

.white {
  background-color: white;
}

.page-content {
  background-color: white;
}

.warda {
  padding-right: 2%;
  padding-left: 2%;
  font-size: 30px;
  color: #333;
}

body {
  margin: 0;
  padding: 0;
}

.maxcoffe {
  padding-left: 5%;
  padding-right: 5%;
  background-color: #F4F6FA;
}


h2 {
  color: #333;
}

.navlist {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.btn {
  margin: 0 15px;
}

button {
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.continue-btn {
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: 2px solid transparent;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.continue-btn:hover {
  background-color: #b30000;
}

.continue-btn:active {
  background-color: #072C7B;
  border-color: #0056b3;
  color: transparent;
}

.category-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.category-card {
  margin: 10px;
}



.category-Title {
  margin: 0;
}

.category-card.selected {
  transform: scale(1.05);
  color: rgb(41, 127, 185);
}

.Call {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.card {
  width: 400px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(#072C7B);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(#072C7B);
  transform: translateY(-5px);
}

.card h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.form-group select {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  color: #333;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.titpart {
  align-items: center;
  padding-bottom: 10px;
  background-color: #5271ff;
  height: 40px;
  width: 100%;
}


.titpart p {
  color: white;
  padding-top: 10px;
  font-size: 14px;
}

.titpartb {
  font-size: medium;
  color: rgb(0, 0, 0);
  padding-top: 5px;
  padding-right: 5px;
}

.button-container {
  text-align: center;
}

.button {
  padding: 10px 20px;
  background-color: #fdcb33;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #f8bc00;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.card {
  width: 400px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(#072C7B);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(#072C7B);
  transform: translateY(-5px);
}

.card h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  color: #666;
}

.form-group select {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  background-color: #F4F6FA;
  color: #333;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.button-container {
  text-align: center;
}

.button {
  padding: 10px 20px;
  background-color: #fdcb33;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.cart-item {
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 1200px;
}

.call {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  max-width: 1200px;
}

.button:hover {
  background-color: #f8bc00;
}

.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.category-image {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.bannersol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.categorycntainerContainer {
  background-color: #F4F6FA;
  width: 100%;

}

.categorycntainer {
  display: flex;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 30px;
}

.categoryiem {
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: 3px solid #000;
}

.categoryitem {
  cursor: pointer;
  background-color: rgba(77, 74, 74, 0);
  border-bottom: 5px solid #ffffff00;
  background-color: #F4F6FA;
}

.TitlConta {
  font-size: 18px;
  color: #072C7B;
  font-weight: bold;
}

.categoryitem:hover {
  border-bottom: 3px solid #5271ff;
}

.categoryitem:hover {
  border-bottom: 3px solid #072C7B31;
}

.categoryitem.selected {
  border-bottom: 5px solid #5271ff;
}

.categoryitem p {
  font-weight: bold;
  padding: 20px;
}

.carto {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  margin: 16px 0;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.carto:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.cartfo {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.cartmage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;
  border: 1px solid #ddd;
}

.cart-item-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 8px;
  color: #333;
}

.cart-item-price {
  font-size: 16px;
  color: #ff4b5c;
  margin: 0 0 8px;
}

.cart-item-quantity {
  font-size: 14px;
  color: #777;
}

.cart-item-actions {
  display: flex;
  justify-content: flex-end;
}

.cart-item-actions button {
  background-color: #F4F6FA;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cart-item-actions button:hover {
  background-color: #ff333a;
  transform: scale(1.05);
}

.process-flow-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F4F6FA;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
}

.process-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.process-card img {
  width: 100px;
  height: auto;
}

.cart-text {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #072C7B;
}

.textBCont {
  font-size: 12px;
  color: #666;
}

.connector {
  height: 2px;
  width: 40px;
  margin: 0 20px;
  position: relative;
  flex-shrink: 0;
}


.connector::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: repeating-linear-gradient(to right,
      #333 0%,
      #333 50%,
      transparent 50%,
      transparent 100%);
  background-size: 10px 2px;
}

.connector::after {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 2px;
  box-shadow: 0 0 5px 1px #333;
}

@media (max-width: 768px) {
  .process-flow-container {
    flex-direction: column;
    align-items: center;
  }

  .page_CCcontent {

    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #F4F6FA;
  }

  .process-card {
    margin: 20px 0;
  }

  .process-card img {
    width: 200px;
    height: auto;
  }

  .connector {
    width: 2px;
    height: 40px;
    margin: 20px 0;
  }

  .connector::before,
  .connector::after {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 2px;
    background: repeating-linear-gradient(to bottom,
        #333 0%,
        #333 50%,
        transparent 50%,
        transparent 100%);
    background-size: 2px 10px;
  }

  .bluebody {
    background-color: #072C7B;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
  }


  .marvelbodye {
    background-color: #F4F6FA;
    ;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
  }

  .whiteDbodye {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }
}


.marvelbodye {
  background-color: #F4F6FA;
  ;
  display: flex;
  flex-direction: column;

}

.whiteDbodye {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}


.buttons {
  background-image: linear-gradient(to right, #0741A2 0%, #0056B3 50%, #005DB8 100%);
  padding: 10px 20px;
  color: white;
  font-family: 'Tajawal-Regular';
  font-size: 16px;
  text-align: center;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
}

.buttons:hover,
.buttons:focus {
  background-image: linear-gradient(to right, #0056B3, #0741A2);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.bluebodye {
  background-color: #072C7B;
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  padding-right: 15%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .bluebodye {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.buttons:active {
  background-color: #072C7B;
  color: #FFFFFF;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .warda {
    padding-right: 2%;
    padding-left: 2%;
    font-size: 20px;
    color: #333;
  }

  .categoryitem {
    flex: 1 1 20%;
    text-align: center;
    padding: 10px;
    margin: 5px;
    background-color: rgba(77, 74, 74, 0);
    border-bottom: 5px solid #ffffff00;
    background-color: #F4F6FA;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .categoryitem:hover {
    border-bottom: none;
  }

  .categorycntainer {
    flex-direction: column;
    padding-top: 500px;
    background-color: #666;
    width: max-content;
  }


  .categoryitem p {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 14px;
  }

  .banner {
    height: 200px;
  }

  .category-container {
    flex-direction: column;
    padding: 10px;
  }

  .cart-item-info,
  .cart-summary {
    flex-direction: column;
    align-items: start;
  }

  .cart-item-image {
    width: 60px;
    height: 60px;
  }


  .categoryitemPhone {
    flex: 1;
    margin: 5px;
    padding: 10px;
    cursor: pointer;
    background-color: #F4F6FA;
    text-align: center;
  }

  .categoryitemPhone.selected {
    border-color: #f4f6fa00 #f4f6fa00 #072C7B #f4f6fa00;
  }

  .categoryitemPhone:hover {
    cursor: pointer;
    border-style: solid;
    border-color: #f4f6fa00 #f4f6fa00 #072C7B #f4f6fa00;
  }

  .categoryitemPhone p {
    color: #000000;
    margin: 0;
    font-size: 13px;

  }

  .categoryitemPhone p:hover {
    margin: 0;
    font-weight: bold;
    color: #000000;
    size: 12px;
  }

  .categoryitemPhone:hover {
    border-bottom: 2px solid #5271ff;
  }

  .categoryitemPhone:hover {
    border-bottom: 2px solid #072C7B31;
  }

  .categoryitemPhone.selected {
    border-bottom: 3px solid #5271ff;
  }

  .warda {
    padding-right: 2%;
    padding-left: 2%;
    font-size: 20px;
    color: #333;
  }


}

.TitlContwP {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.TitlContw {
  font-size: 20px;
  color: #333;
}

.oxbarbtn:hover {
  background-color: #F4F6FA;
  color: #000000;
}

.cardContactUE {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
  text-align: center;
  width: 50%;
}

.cardContactUE:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.cardContactUE img {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.Desplays {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.romel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa00;
}

.tabra1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  gap: 5px;
}

.tabra2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.tabra3 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #0056B3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  font-size: 16px;
}

.tabra4 {
  text-align: center;
  font-size: 10px;
}

.tabra5 {
  font-size: 20px;
  margin-bottom: 30px;
}

.tabra6 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  font-size: 16px;
}



.page_CCcontent {
  display: flex;
  align-items: center;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.categorycntainerPhone {
  background-color: #F4F6FA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #072C7B71;

}

.khayen {

  width: 100%;
}

.categoryitemPhone {
  flex: 1;
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  background-color: #F4F6FA;
  text-align: center;
}

.categoryitemPhone.selected {
  border-color: #f4f6fa00 #f4f6fa00 #072C7B #f4f6fa00;
}

.categoryitemPhone:hover {
  cursor: pointer;
  border-style: solid;
  border-color: #f4f6fa00 #f4f6fa00 #072C7B #f4f6fa00;
}

.categoryitemPhone p {
  color: #000000;
  margin: 0;
}

.categoryitemPhone p:hover {
  margin: 0;
  font-weight: bold;
  color: #000000;
}





.categoryitemPhone:hover {
  border-bottom: 2px solid #5271ff;
}

.categoryitemPhone:hover {
  border-bottom: 2px solid #072C7B31;
}

.categoryitemPhone.selected {
  border-bottom: 3px solid #5271ff;
}



.app-containerrr {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
}

.whiteamene {
  background-color: white;
}

.servicescontainer {
  background-color: #072C7B;
}


.categories-wrapper {  font-family: 'Tajawal-Regular';

  display: inline-flex;
  width: 100%;
  gap: 10px;
  padding-left: 20px;
  padding-right: 7px;
  padding-top: 5px;
}


.service-list {
  list-style-type: none;
  padding: 0;
}

.service-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 20px;
}










.morese {
  background-color: white;
}









@media (max-width: 768px) {

  .speed2desktopr:hover {
    transform: scale(1.025);
  }

}





.desktop-view {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
}

.speed2desktopr {
  background-color: #f4f6fa;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.speed2desktopr:hover {
  transform: scale(1.025);
}

/* Image styling */
.speed3desktop {
  width: auto;
  /* Auto width to maintain aspect ratio */
  height: auto;
  max-width: 100%;
  /* Make sure image fits within container */
  max-height: 150px;
  /* Limit the height */
  margin-bottom: 10px;
  border-radius: 4px;
}

.speed4desktop {
  font-size: 18px;
  font-weight: bold;
  color: #5271ff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.speed45desktop {
  /* font-family: 'NotoSansArabic-VariableFont_wdth,wght';
  src: url('./fonts/NotoSansArabic-VariableFont_wdth,wght.ttf') format('truetype'); */

  font-size: 14px;
  font-weight: bold;
  color: #5271ff;
}

.speed53desktop {
  padding-top: 5px;
  font-size: 12px;
  color: #000000;
  line-height: 1.5;
  text-align: justify;
}


@media only screen and (max-width: 768px) {
  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: flex;
    flex-direction: column;
  }

  .speed2 {
    background-color: #faf4f4;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .speed3 {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .speed4 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }

  .speed5 {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .desktop-view {
    grid-template-columns: repeat(2, 1fr);
  }

  .mobile-view {
    display: none;
  }
}

/* Desktop layout */
@media only screen and (min-width: 1025px) {
  .mobile-view {
    display: none;
  }
}







.centrPi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: column;
  text-align: center;
}

.centrPip {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
  text-align: center;
  margin: 0;
}















.category-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.category-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: black;
  border-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  gap: 10px;
}
.category-button.active p {
  color: white;
}



.category-button:hover {
  border-color: rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 123, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.3);

}

.category-icon {
  width: 50px;
  height: 50px;
  margin-left: 4px;
}

.mobilefilterhi {
  max-height: 100vh;
  /* Restrict to viewport height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  -webkit-overflow-scrolling: touch;
  /* Smooth scrolling for mobile devices */
  padding: 10px;
}



.category-icon24 {
  width: 24px;
  height: 24px;
  padding: 7px;
}



@media (max-width: 480px) {}

.caton {
  width: 20px;
  height: 20px;
}

.category-text {
  font-size: 12px !important;
  color: #000;
  text-align: center;
}

/* Link styling */
.category-link {
  text-decoration: none;
  color: inherit;
  /* Inherit color for consistent look */
}

@media (max-width: 600px) {
  .category-grid {
    gap: 10px;
    /* Adjust spacing for smaller screens */
  }

  .category-button {
    padding: 10px;
  }

  .category-text {
    font-size: 12px;
    /* Smaller text for compact layout */
  }
}











































.filter-container {
  margin-bottom: 16px;
}

.button-group-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two buttons per row */
  gap: 12px;
  /* Spacing between buttons */
  margin-top: 10px;
}

.filter-button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.filter-button:hover {
  background-color: #e0e0e0;
}

.filter-button.active-button {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}






















/* Main Layout */
.main-layout {
  display: flex;
  flex-direction: row;
  /* Horizontal alignment */
  gap: 1rem;
  /* Space between blue and red sections */
  align-items: flex-start;
  /* Align sections at the top */
}

/* Blue Section */
.blue-section {
  flex: 3;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  color: #fff;
  overflow: hidden;
}

/* Red Section */
.red-section {
  flex: 1;
  /* Takes up less space */
  background-color: #ffffff;
  /* Red */
  border-radius: 5px;
  color: #fff;
  /* White text */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

















/* General Section Styling */
.red-title-section {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.2rem;
  color: #ff0000;
  /* Red text for section titles */
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
}

/* Buttons Row */
.red-buttons-row {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two buttons per row */
  gap: 0.5rem;
  justify-items: center;
  /* Center the buttons */
}

/* Individual Button */




/* Icon Inside Button */
.button-icon {
  margin-right: 0.5rem;
  font-size: 30px;
}

/* More Button */


/* More Button Hover Effect */









































/* Layout Visibility */
.desktopV {
  display: block;
  padding-top: 20px;
}

.mobileV {
  display: none;
  /* Hidden on desktop */
}

.mobile-filter-panel {
  position: fixed;
  top: -1000px;
  /* Start completely off-screen */
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  /* Subtle background color */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* Slight shadow for depth */
  z-index: 1000;
  /* Ensure it's above other elements */
  overflow-y: auto;
  transition: top 0.3s ease-in-out;
  /* Smooth sliding transition */
}


.mobile-filter-panel.visible {
  top: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .desktopV {
    display: none;
    /* Hide desktop content on mobile */
  }

  .mobileV {
    display: block;
    /* Show mobile content */
  }
}

.mobile-filter-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent dark overlay */
  z-index: 999;
  /* Below the panel */
  display: none;
  /* Initially hidden */
}

.mobile-filter-backdrop.visible {
  display: block;
}

/* Button Hover State */
.category-btn:hover {
  background-color: #eaeaea;
}

/* Active Button State */
.category-btn.active {
  background-color: #5271ff;
  color: white !important;
  border: 2px solid #5271ff;
  font-weight: bold;
}
.category-button.active{
  background-color: #5271ff;
  color: white;
  border: 2px solid #5271ff;
  font-weight: bold;
}
.moreB {
  width: 20%;
  border-radius: 7px;
  border: 1px solid #5271ff;
  color: black;
  background-color: #a5a5a500;
  align-items: center;
}


.moreB:hover {
  border: 1px solid #5271ff;
  color: rgb(255, 255, 255);
  background-color: #5271ff;
}

/* Button Content Layout */
.button-content {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Space between text and image */
}

/* Category Button */
.category-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #333;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
  white-space: nowrap;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 10px 16px;
  margin: 6px;
}

.category-btn:not(.active):hover {
  border: 1px solid #5271ff;
  background-color: #f4f4f4;
  color: #5271ff;
}

/* Button Icon Adjustments */
.category-btn img {
  max-width: 20px;
  /* Ensure icons are responsive */
  max-height: 20px;
  transition: transform 0.3s ease;
}

/* Icon Animation on Hover */
.category-btn:hover img {
  transform: scale(1.1);
  /* Slightly enlarge icon on hover */
}

/* Categories Navigation */
.categories-nav {
  padding-right: 10px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-color: #007BFF #f4f4f4;
  scrollbar-width: thin;
  display: flex;
  gap: 8px;
  /* Space between buttons */
}

/* Scrollbar Styles */
.categories-nav::-webkit-scrollbar {
  height: 8px;
}

.categories-nav::-webkit-scrollbar-thumb {
  background-color: #5271ff;
  border-radius: 10px;
}

.categories-nav::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

@media (max-width: 768px) {
  .categories-nav {
    padding-top: 5px;
    padding-left: 16px;
  }
  .blue-section {
    padding: 0px !important;
  }
  .moreB {
    width: 50%;

  }

  .category-btn {
    font-size: 12px;
    padding: 8px 10px;
  }

  .category-btn img {
    max-width: 16px;
    max-height: 16px;
  }
}

@media (max-width: 480px) {
  .category-btn {
    font-size: 11px;
    padding: 6px 8px;
  }

  .category-btn img {
    max-width: 14px;
    max-height: 14px;
  }
}























.more-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #5271ff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.2s ease, color 0.2s ease;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.more-button:hover {
  background-color: rgba(0, 123, 255, 0.1);
  color: #000000;
}












.red-button img,
.red-button svg {
  width: 18px;
  height: 18px;
  transition: transform 0.2s ease;
}

.red-button:hover img,
.red-button:hover svg {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .red-button {
    padding: 8px 14px;
    font-size: 0.85rem;
    gap: 6px;
  }

  .red-button img,
  .red-button svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 480px) {
  .red-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .red-button img,
  .red-button svg {
    width: 14px;
    height: 14px;
  }
}

.butticons {
  width: 100%;
}

.msadc p {
  font-size: 2px !important;
}



















.padto {
  background-color: red;
  margin-bottom: 20px;
}


.red-button {
  height: 40px;
  width: 100%;
  display: flex;
  padding: 10px 1px;
  background-color: #F4F6FA;
  color: #333;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.3s ease;
  gap: 8px;
}

.red-button:hover {
  transform: translateY(-2px);
  color: #000000;
  background-color: rgba(0, 123, 255, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.red-button.active {
  background-color: #ffffff;
  color: #5271ff;
  border: 1px solid #5271ff;
}







.talej {
  display: flex;
  flex-direction: column; /* Stack service-talej components vertically */
}
/* lcart ka cart hayd hwe */
.service-talej {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px;
  background-color: #fff;
}

/* Row for Image and Title */
.rowrukaya {
  display: flex;
  align-items: center; 
  justify-content: space-between;
/* background-color: red; */
}

.talej-image {
  width: 120px; 
  height: auto;
  margin-right: 1rem;
}

.service-talejh5 {  margin-left: 1rem; 

  font-size: 13px;
  color: #333;
  margin: 0;
  font-weight: bold;
  flex: 1; /* Let the title take available space */
}

/* Second Row for Summary */
.summary-row {
  margin-top: 0.5rem; /* Add space between rows */
  color: #555;
  font-size: 0.9rem;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis; /* Prevent summary overflow */
}

/* Add space between service-talej components */
.talej .service-talej:not(:last-child) {
  margin-bottom: 1rem;
}



.FiltreActivateBanner { 
  font-size: 1.2rem;
  color: #333;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
}
