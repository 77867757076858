.appointments-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.appointments-page h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #007BFF;
    text-align: center;
}

.appointments-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.appointment-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.appointment-item:hover {
    background-color: #e9e9e9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.appointment-item div {
    font-size: 1.1em;
    color: #333;
    margin-bottom: 5px;
}

.appointments-page p {
    font-size: 1.2em;
    color: #666;
    text-align: center;
    margin-top: 30px;
}

.appointments-page div {
    font-size: 1.2em;
    color: #666;
    text-align: center;
}
