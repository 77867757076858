h1 {
  color: #2a2a2a;
  text-align: center;
}

.backgroundsnowwhite {
  background-color: #F4F6FA;
  display: flex;
  flex-direction: column;
  background-color: #F4F6FA;
  padding: 10px;
}

.douzejour {
  background-color: red;
}

.grid-cellule {
  background-color: #F4F6FA;

}

.button_collapsess {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin: 10px 0;

}

.button_collapsess:hover {
  background-color: #f0f0f0;
}

.subtitelcollaps {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.normalcolapsText {
  font-size: 16px;
  color: #000000;
  width: 100%;
}

.titlecsollapseeWak {
  padding-right: 3%;
  flex-grow: 1;
  font-size: 18px;
  color: #FFFFFFFF;
  text-align: left;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
}

.ltr .normalcolapsText {
  text-align: left;
  padding-left: 6%;
}


.ltr .subtitelcollaps {
  text-align: left;
  padding-left: 4%;
}

.ltr .titlenormalcolapsTextt {
  text-align: left;
  padding-left: 12%;
  color: #666;

}




.rtl .normalcolapsText {
  text-align: right;
  padding-right: 6%;

}


.rtl .subtitelcollaps {
  text-align: right;
  padding-right: 4%;

}

.sostitel {
  font-size: 17px;
  color: #666;
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.rtl .sostitel {
  font-size: 17px;
  display: flex;
  flex-direction: column;

  padding-right: 12%;
}

.ltr .sostitel {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 12%;
}



.titlenormalcolapsText {
  font-size: 16px;
  color: #000000 !important;
  width: 100%;
}

.sostext {
  font-size: 16px;
  color: #666;
  width: 100%;
}

.ltr .sostext {
  text-align: left;
  padding-left: 15%;
  font-size: 16px;
  width: 100%;
  color: #666;

}

.rtl .sostext {
  text-align: right;
  padding-right: 15%;
  font-size: 16px;
  color: #666;

}



@media (max-width: 600px) {
  .subtitelcollaps {
    margin-left: 10px;
    flex-grow: 1;
    font-size: 18px;
    color: #000000;
    font-weight: bold;
  }
}

.widthbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 5px;
  width: 100%;
  max-height: max-content;
}



.buttonRow {
  display: flex;
  justify-content: space-between;
}

.widthbutton {
  flex: 1;
  margin: 0 5px;
}

.sendbtn {
  width: 120px;
  padding: 10px;
  font-size: 14px;

  transition: background-color 0.3s ease;
}



@media (max-width: 1200px) {
  .sendbtn {
    width: 110px;
    padding: 9px;
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .sendbtn {
    width: 100px;
    padding: 8px;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .sendbtn {
    width: 90px;
    padding: 7px;
    font-size: 11px;
  }
}

@media (max-width: 576px) {
  .sendbtn {
    width: 80px;
    padding: 6px;
    font-size: 10px;
  }
}



.ltr .titlenormalcolapsTextW {
  text-align: left;
  padding-left: 12%;

}

.rtl .titlenormalcolapsTextW {
  text-align: right;
  padding-right: 12%;

}

.titlenormalcolapsTextW {
  font-size: 16px;
  color: #F4F6FA;
  width: 100%;
  font-weight: bold;

}

.BluedivHC {
  background-color: #5271ff;
  height: 30px;
  display: flex;
  align-items: center;
  align-self: center;
  width: 100%;
  padding: 0 10px;
  gap: 10px;
}


.details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.item-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80%;
  max-width: 600px;
}

.item-card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.item-card p {
  margin-top: 10px;
  font-size: 16px;
}

.price {
  font-weight: bold;
  color: #4A90E2;
}

.no-data {
  font-style: italic;
}

.TitlContwo {
  font-size: 20px !important;
  color: #000000;
  font-weight: bold;
}

.titlecollapseeomar {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
  display: flex;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: #5271ff;
}

.titlecollapseeW {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  text-align: left;
  display: flex;
  padding-right: 10px;
}

.titlecollapseeWak {
  padding-right: 3%;
  flex-grow: 1;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  text-align: left;
  display: flex;
  width: 100%;
}

.titlecollapseeWakhussein {
  flex-grow: 1;
  font-size: 17px;
  color: #5271ff;
  text-align: left;
  display: flex;
  width: 100%;
}

@media (max-width: 600px) {



  .titlecollapsee {
    margin-left: 10px;
    flex-grow: 1;
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    text-align: left;
    display: flex;

  }

  .titlecollapseeW {
    margin-left: 10px;
    flex-grow: 1;
    font-size: 18px;
    color: #F4F6FA;
    font-weight: bold;
    text-align: left;
    display: flex;

  }


  .det_gr {
    grid-template-columns: 1fr;
  }

  .sostitel {
    font-size: 14px;
    flex-direction: column;
  }

  .rtl .sostitel {
    font-size: 14px;
    flex-direction: column;
    font-weight: bold;
    padding-left: 5px;

  }

  .ltr .sostitel {
    font-size: 14px;
    flex-direction: column;
    padding-right: 5px;
    font-weight: bold;

  }





  .contcoffee li {
    position: relative;
    display: flex;
    align-items: flex-start !important;
    justify-content: start !important;
    justify-self: start !important;
  }

  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .contcoffee li {
      text-align: justify !important;
    }
  }








  .sostext {
    font-weight: normal;

    font-size: 14px;
  }

  .rtl .sostext {
    font-weight: normal;

    font-size: 14px;
  }

  .ltr .sostext {

    font-weight: normal;


    font-size: 14px;
  }
}



.det_gr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 20px 0;
}

.grid-cell {
  background-color: #f8f8f8;
  padding: 10px;

}

.Bluediv {
  background-color: #F4F6FA;
  height: 30px;
  display: flex;
  align-items: center;
  align-self: center;
  width: 100%;
  padding: 0 10px;
  gap: 10px;
}


.Bluedivsec {
  background-color: #ffffff;
  height: 30px;
  display: flex;
  align-items: center;
  align-self: center;
  width: 100%;
  padding: 0 10px;
  gap: 10px;
}


.Bluediv p {
  margin: 0;
  padding: 0;
}

.circle-list-itemDetails {
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-top: 2px;
}

.circle-list-itemDetails::before {
  content: '';
  position: absolute;
  top: 10px;
  width: 10px;
  height: 10px;
  background-color: #072C7B;
  border-radius: 50%;
}

 

.rtl .circle-list-itemDetails {
  padding-right: 2%;
  margin-right: 1.5%;
}

.rtl .circle-list-itemDetails::before {
  right: 0;
}

.ltr .circle-list-itemDetails::before {
  left: 0;
}

.ltr .circle-list-itemDetails {
  padding-left: 2.5%;
  margin-left: 1.3%;
}













@media (max-width: 768px) {
  .circle-list-itemDetails {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px; /* Reduced spacing for mobile */
    padding-top: 1px; /* Adjusted padding for better fit */
  }

  .circle-list-itemDetails::before {
    content: '';
    position: absolute;
    top: 10px; /* Reduced top alignment for smaller font sizes */
    width: 8px; /* Smaller icon size for mobile */
    height: 8px; /* Smaller icon size for mobile */
    background-color: #072C7B;
    border-radius: 50%;
  }

  .rtl .circle-list-itemDetails {
    padding-right: 5%; /* Slightly increased padding for readability */
    margin-right: 3.5%;
  }

  .rtl .circle-list-itemDetails::before {
    right: 0;
  }

  .ltr .circle-list-itemDetails::before {
    left: 0;
  }

  .ltr .circle-list-itemDetails {
    padding-left: 6%; /* Slightly increased padding for readability */
    margin-left: 3%;
  }
}













.safamak {
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.safamak::before {
  content: '';
  position: absolute;
  top: 0;
  /* Align with the top of the first line */
  left: 0;
  /* Position it to the left of the text */
  width: 10px;
  height: 10px;
  background-color: #072C7B;
  border-radius: 50%;
  margin-top: 6px;
  /* Fine-tune the vertical alignment */
}

.ltr .safamak {
  padding-left: 20px;
}

.rtl .safamak {
  padding-right: 20px;
}

.ltr .safamak::before {
  left: 0;
}

.rtl .safamak::before {
  right: 0;
}


.habaa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 5px;
  width: 100%;
  background-color: #F4F6FA;
}


@media (max-width: 768px) {
  .TitlContwo {
    font-size: 20px;
  }

  .titlecollapsee {
    font-size: 16px;
  }

  .titlecollapseeW {
    font-size: 16px;
  }


  .titlenormalcolapsText {
    font-size: 14px;


  }

  .grid-cell {
    background-color: #f8f8f8;
    padding: 10px;



    display: flex;
  }

}

.contIcon {
  width: 3.5%;
}

.contIcon img {
  width: 100%;
  height: 100%;
}

.iconTrue {
  padding-right: 3%;
  padding-left: 3%;
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.iconTrue::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.ltr .iconTrue {
  padding-left: 20px;
}

.ltr .iconTrue::before {
  left: 0;
}

.rtl .iconTrue {
  padding-right: 20px;
}

.rtl .iconTrue::before {
  right: 0;
}











