.hospital-service-add {
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 20px;
    background-color: #072C7B;;
}

.hospital-card {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.location {
    font-size: 1.2em;
    color: #6c757d;
    margin-bottom: 20px;
}

.service-detail {
    border-top: 1px solid #dee2e6;
    padding-top: 20px;
    margin-top: 20px;
}

.service-description {
    font-size: 1.3em;
    color: #495057;
    margin-bottom: 10px;
}

.doctor,
.booking-details {
    font-size: 1.1em;
    color: #6c757d;
    margin-bottom: 10px;
}

.service-options {
    margin-top: 20px; 
}

.options-title {
    font-size: 1.2em;
    color: #343a40;
    margin-bottom: 10px;
}

.location-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.location-icon {
    width: 20px;
}

.location-text {
    font-size: 16px;
    color: #333;
}

.locofe {
    display: flex;
    align-items: center;
    margin-top: 18px;
}

.hospital-service-add[dir="rtl"] .locofe {
    margin-right: 18px;
    margin-left: 0;
    padding-right: 5px;
}
.hospital-service-add[dir="ltr"] .locofe {
    margin-left: 18px;
    margin-right: 0;
    padding-left: 5px;
}
.hospital-subtitle {
    font-size: 14px;
    color: #6c757d;
    margin-top: 5px;
    margin-bottom: 15px;
}



.hospital-name {
    font-size: 24px;
    font-weight: bold;
    color: #072C7B;
}

.valueAdded {
    background-color: #F4F6FA;
    display: flex;
    padding: 10px;
}

.whiteC {
    font-weight: bold;
    color: #072C7B;
    font-size: 20px;
}

.hospital-logo {
    width: 100px;
    margin-inline-end: 10px;
}

.Services-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
}

.Services-button:hover,
.Services-button:focus {
    background-image: linear-gradient(to right, #0056B3, #0741A2);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
}

.Services-button:active {
    background-color: #072C7B;
    color: #FFFFFF;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
}s

.hospital-service-add[dir="rtl"] {
    text-align: right;
    padding-right: 15%;
}

.hospital-service-add[dir="ltr"] {
    text-align: left;
    padding-left: 15%;
}


.option-item {
    text-align: right;
    direction: rtl;
    padding: 5px;
    margin: 5px 0;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    justify-content: space-between;
}

.optionsitem {
    text-align: right;
    direction: rtl;
    padding: 5px;
    margin: 5px 0;
    align-items: center;
    justify-content: flex-start;
    display: flex;
}

.option-item span {
    margin-left: 10px;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .hospital-card {
        padding: 15px;
        width: 90%;
    }

    .service-description {
        font-size: 1.1em;
    }

    .doctor,
    .booking-details {
        font-size: 1em;
    }

    .options-title {
        font-size: 1.1em;
    }

    .location {
        font-size: 1em;
        margin-bottom: 15px;
    }

    .hospital-name {
        font-size: 22px;
    }

    .whiteC {
        font-size: 18px;
    }

    .hospital-logo {
        width: 80px;
    }

    .Services-button {
        font-size: 1em;
        padding: 8px 16px;
        margin-top: 15px;
    }
}

@media (max-width: 480px) {
    .hospital-card {
        padding: 10px;
        width: 100%;
    }

    .service-description {
        font-size: 1em;
    }

    .doctor,
    .booking-details {
        font-size: 0.9em;
    }

    .options-title {
        font-size: 1em;
    }

    .location {
        font-size: 0.9em;
        margin-bottom: 10px;
    }

    .hospital-name {
        font-size: 20px;
    }

    .whiteC {
        font-size: 16px;
    }

    .hospital-logo {
        width: 60px;
    }

    .Services-button {
        font-size: 0.9em;
        padding: 6px 12px;
        margin-top: 10px;
    }
}




.blueTkhile {
    padding: 12px;
    width: 150px;
    background-image: linear-gradient(to right, #007BFF 0%, #007BFF 50%, #007BFF 100%);
    color: #F4F6FA;
    font-size: 16px;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    box-shadow: 3px 3px 8px #0000004d;
  }
  
  .blueTkhile:hover,
  .blueTkhile:focus {
    background-image: linear-gradient(to right, #007BFF, #007BFF);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.5);
  }
  
  .blueTkhile:active {
    background-color: #007BFF;
    color: #F4F6FA;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .blueTkhile p {
    color: #F4F6FA;
    font-size: small;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .blueTkhile {
      width: 100px;
      padding: 10px;
      font-size: 14px;
    }
  
    .blueTkhile p {
      font-size: x-small;
    }
  }
  
  @media (max-width: 400px) {
    .blueTkhile {
      width: 80px;
      padding: 8px;
      font-size: 12px;
    }
  
    .blueTkhile p {
        font-size: x-small;
    }
  }