.backgroundsnowwhite {
  background-color: #F4F6FA;

}
.bahala {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Distributes space evenly */
  align-items: center; /* Aligns items vertically in the middle */
  gap: 10px; /* Adds space between items */
}
.button_collapsess {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin: 10px 0;

}

.button_collapsess:hover {
  background-color: #F4F6FA;
}











.subtitelcollaps {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}

.normalcolapsText {
  background-color: #F4F6FA;
  font-size: 16px;
  color: #000000;
  width: 100%;
}

.ltr .normalcolapsText {
  text-align: left;
  background-color: #F4F6FA;
  padding-left: 6%;
}


.ltr .subtitelcollaps {
  text-align: left;
  background-color: #F4F6FA;
  padding-left: 4%;
}

.ltr .titlenormalcolapsText {
  background-color: #F4F6FA;
  text-align: left;
  padding-left: 12%;

}

.ltr .titlenormalcolapsTextULX {
  text-align: left;
  background-color: #F4F6FA;
  padding-left: 15%;

}

.rtl .normalcolapsText {
  text-align: right;
  padding-right: 6%;

}

.rtl .subtitelcollaps {
  text-align: right;
  padding-right: 4%;

}


.rtl .titlenormalcolapsText {
  background-color: #F4F6FA;
  text-align: right;
  padding-right: 12%;

}

.rtl .titlenormalcolapsTextULX {
  text-align: right;
  padding-right: 15%;
  background-color: #F4F6FA;

}

.titlenormalcolapsText {
  font-size: 16px;
  color: #F4F6FA;
  color: #0056B3;
  width: 100%;
  font-weight: bold;

}

.titlenormalcolapsTextULX {
  font-size: 16px;
  color: #000000;
  width: 100%;
  background-color: #F4F6FA;

}



.widthbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
}

.widthbutton {
  flex: 1;
  margin: 0 5px;
}

.sendbtn {
  width: 100%;
}

.snow {
  background-color: #F4F6FA;

}

.ltr .titlenormalcolapsTextW {
  text-align: left;
  padding-left: 12%;

}

.rtl .titlenormalcolapsTextW {
  text-align: right;
  padding-right: 12%;

}

.titlenormalcolapsTextW {
  font-size: 16px;
  color: #F4F6FA;
  width: 100%;
  font-weight: bold;

}

.rtl .bahite {
  width: 80%;
  flex-direction: column;
  align-self: center;

}

.ltr .bahite {
  width: 80%;
  flex-direction: column;
  align-self: center;
}


@media (max-width: 600px) {
  .subtitelcollaps {
    margin-left: 10px;
    flex-grow: 1;
    font-size: 18px;
    color: #000000;
    font-weight: bold;
  }

  .rtl .bahite {
    width: 90%;
    flex-direction: column;
    align-self: center;

  }

  .ltr .bahite {
    width: 90%;
    flex-direction: column;
    align-self: center;
  }
}

.whiteH {
  background-color: #fff;
}



[dir="ltr"].button_collapses span {
  display: inline-block;
  transition: transform 0.3s ease;
}

[dir="rtl"].button_collapses span {
  display: inline-block;
  transition: transform 0.3s ease;
}

[dir="ltr"].button_collapses:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}

[dir="rtl"] .button_collapses:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);
}

[dir="rtl"] .button_collapses:active {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}

[dir="ltr"].button_collapses:active {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}



[dir="rtl"] .button_collapses {
  flex-direction: row-reverse;
  background-color: #ffffff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  text-align: start;
  text-align: right;
  transition: background-color 0.3s ease;

}

[dir="ltr"] .button_collapses {
  text-align: left;
  background-color: #ffffff;
  text-align: end;
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

















[dir="ltr"].button-collapsess span {
  display: inline-block;
  transition: transform 0.3s ease;
}

[dir="rtl"].button-collapsess span {
  display: inline-block;
  transition: transform 0.3s ease;
}

[dir="ltr"].button-collapsess:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}

[dir="rtl"] .button-collapsess:focus {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);
}

[dir="rtl"] .button-collapsess:active {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}

[dir="ltr"].button-collapsess:active {
  background-color: #F4F6FA;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0);

}



[dir="rtl"] .button-collapsess {
  flex-direction: row-reverse;
  background-color: #ffffff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  text-align: start;
  text-align: right;
  transition: background-color 0.3s ease;

}

[dir="ltr"] .button-collapsess {
  text-align: left;
  background-color: #ffffff;
  text-align: end;
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;

}


.textConta {
  color: #333;
  font-weight: bold;
  font-size: medium;
}
 

.cleopaetra1011, .widthbutton {
  flex: 1; /* Allows buttons to take equal width */
}