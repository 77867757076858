.cleopatra1 {
    background-color: #ffffff;
    padding: 40px 20px;
    text-align: center;
    width: 100%;
    overflow: hidden; 
    box-sizing: border-box; 
}


.cleopatra2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: #000000;
}

.cleopatra2 button {
    background-color: #072C7B;
    color: white;
    border: none;
    padding: 12px 25px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 8px;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Carousel styles */
.slick-slider {
    position: relative;
    box-sizing: border-box;
    height: auto;
}

.slick-slide {
    margin-right: 0;
}

.slick-track {
    display: flex;
    gap: 10px;
}

.partner-item {
    text-align: center;
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
    border: none;
    transform: scale(1);
    background-color: white;
}

.partner-item:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.partner-item img {
    width: 150px;
    height: auto;
    margin-bottom: 10px;
    max-width: 100%;
    border-radius: 10px;
}

.partner-item p {
    font-size: 1rem;
    color: #333;
    margin: 5px 0;
}

/* Carousel navigation dots */
.slick-dots li button:before {
    font-size: 10px;
    color: #072C7B;
}

.slick-dots li.slick-active button:before {
    color: #0056b3;
}

/* Section around the carousel */
.cleopatra3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 100%;
    margin-bottom: 10px;
    background-color: white;
}

.cleopatra3 img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* Statistics section styles */
.Chloe-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
}

.Chloe1, .Chloe2, .Chloe3, .Chloe4, .Chloe5 {
    width: 18%;
    margin: 10px;
    text-align: center;
}

.Chloe-icon {
    font-size: 2.5rem;
    color: #072C7B;
    margin-bottom: 10px;
}

.Chloe-text {
    font-size: 1.5rem;
    font-weight: bold;
}

.Chloe-subtext {
    font-size: 1rem;
    color: #666;
}

/* Button container */
.cleopaetra10 {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.buttonclerop {
    background-color: transparent;
    color: #000;
    padding: 12px 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.buttonclerop:hover {
    background-color: #072C7B;
    color: white;
}

/* Responsive styles for tablets */
@media (max-width: 1024px) {
    .cleopatra2 {
        font-size: 1.6rem;
    }

    .Chloe1, .Chloe2, .Chloe3, .Chloe4, .Chloe5 {
        width: 30%;
    }

    .partner-item img {
        width: 120px;
    }

    .cleopatra2 button {
        padding: 10px 20px;
    }
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
    .cleopatra2 {
        font-size: 1.4rem;
    }

    .cleopatra3 {
        height: auto;
    }

    .partner-item img {
        width: 100px;
    }

    .Chloe-container {
        flex-direction: column;
        align-items: center;
    }

    .Chloe1, .Chloe2, .Chloe3, .Chloe4, .Chloe5 {
        width: 80%;
        margin: 15px 0;
    }

    .buttonclerop {
        font-size: 0.9rem;
        padding: 10px 15px;
    }
}
