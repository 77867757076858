.tabby1-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Ensure the container takes the full height of the viewport */
}
.paymentfor{
    color: #F4F6FA;

}
.tabby1-header {
    font-size: 24px;
    margin-bottom: 20px;
}

.tabby1-error-message {
    color: red;
}

.tabby1-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabby2-group, .tabby3-group, .tabby4-group, .tabby5-group {
    margin-bottom: 15px;
    width: 100%;
}

.tabby2-value, .tabby4-value, .tabby5-value {
    font-size: 18px;
    margin-top: 5px;
}

.tabby3-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.tabby6-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #072C7B;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tabby6-button:hover {
    background-color: #005bb5;
}

.tabby-branding {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.tabby-logo {
    width: 100px;
    margin-right: 10px;
}

.tabby-message {
    font-size: 14px;
    color: #333;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%; /* Ensure the card takes full width within the container */
    max-width: 600px; /* Limit the maximum width of the card */
}

.card-background {
    background-color: #e0e0e0;
}

.card-white {
    background-color: #ffffff;
}

@media (max-width: 600px) {
    .tabby1-container {
        padding: 10px;
    }

    .tabby1-header {
        font-size: 20px;
    }

    .tabby2-value, .tabby4-value, .tabby5-value {
        font-size: 16px;
    }

    .tabby3-select {
        font-size: 14px;
    }

    .tabby6-button {
        font-size: 16px;
    }

    .tabby-logo {
        width: 80px;
        margin-right: 5px;
    }

    .tabby-message {
        font-size: 12px;
    }

    .card {
        padding: 15px;
    }
}
