.detailsContent {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  text-align: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Banner style */
.bannersollana {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

/* Summary text style */
.summaryText {
  font-size: 1.2em;
  color: #555;
  line-height: 1.6;
  text-align: center;
  margin: 20px 0;
  padding: 0 15px;
}

/* Section style */
.section {
  margin-bottom: 10px;
  width: 100%; /* Ensure sections take full width */
}

/* Button collapses style */
.button-collapsess {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  padding: 10px 0;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #0056B3;
}

/* Title collapses style */
.titlecollaps {
  margin: 0;
  font-size: 1em;
  flex-grow: 1;
  text-align: center;
}

/* Section content style */
.sectionContent {
  padding: 10px 20px;
  background-color: #F4F6FA;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
  width: 100%; /* Ensure content takes full width */
  max-width: 800px; /* Match the max-width of detailsContent */
  margin: 0 auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.custom-table th, .custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.custom-table th {
  background-color: #f2f2f2;
  text-align: left;
  font-size: 18px; /* Adjust font size for table header */
}

.custom-table td {
  font-size: 16px; /* Adjust font size for table header */
}

/* Button style */
.omarbuttons {
  background-color: #0056B3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.omarbuttons:hover {
  background-color: #0057b38c;
}

@media (max-width: 480px) {
  .summaryText {
    font-size: 0.9em;
  }

  .button-collapsess {
    font-size: 0.9em;
  }

  .titlecollaps {
    font-size: 0.8em;
  }

  .omarbuttons {
    padding: 6px 12px;
    font-size: 0.9em;
  }
}

/* RTL specific styles */
.rtl .detailsContent {
  text-align: right;
}

.rtl .summaryText {
  text-align: right;
}

.rtl .button-collapsess {
  justify-content: flex-start;
}

.rtl .titlecollaps {
  text-align: right;
}

.rtl .sectionContent {
  text-align: right;
}

/* LTR specific styles */
.ltr .detailsContent {
  text-align: left;
}

.ltr .summaryText {
  text-align: left;
}

.ltr .button-collapsess {
  justify-content: flex-start;
}

.ltr .titlecollaps {
  text-align: left;
}

.ltr .sectionContent {
  text-align: left;
}

.titlecollapsanouch {
  margin-left: 10px;
  flex-grow: 1;
  font-size: 24px;
  text-align: left;
  display: flex;
}

@media (max-width: 600px) {
  .titlecollapsanouch {
    margin-left: 10px; 
    flex-grow: 1;
    font-size: 22px;
    text-align: left;
    display: flex;
  }
}

/* Additional styles */
.backgroundblue {padding-top: 30px;
  background-color: #072C7B;
}

.backgroundwhitelana {
  background-color: #072C7B;
}

.TitlContFatenlana {
  font-size: 2em;
  margin: 20px 0;
  color: #545454;
}
.TitlContFatenlanaBB {
  font-size: 2em;
  margin: 20px 0;
  color: #072C7B;
  font-weight: bold;

}
.subsummary{
  color: #545454;
  font-weight: bold;
  font-size: 1.2em;

}
@media (max-width: 480px) {
  .backgroundblue {
    padding-left: 5%;
    padding-right: 5%;
  }
}
.green-icon {
  color: green;
  margin-right: 8px;
  width: 24px; /* Adjust size as necessary */
  height: 24px; /* Adjust size as necessary */
  margin-right: 8px;
  padding: 2px; /* Adjust padding as necessary */
  font-size: 18px; /* Adjust font size as necessary */
  display: inline-flex;
}
@media (max-width: 600px) {
  .detailsContent {
    padding: 15px;
  }

  .summaryText {
    font-size: 1.1em;
  }

  .button-collapsess {
    font-size: 1.1em;
  }

  .titlecollaps {
    font-size: 1em;
  }

  .sectionContent {
    padding: 15px;
  }

  .custom-table th, .custom-table td {
    font-size: 0.85em; 
  }

  .omarbuttons {
    padding: 10px 18px;
    font-size: 0.95em;
  }
  .div2helped {
    width: 90%;
  }
  .divhelped{
    width: 90%;

  }
}
.divhelped {
  margin: 20px 0;
}

.div2helped {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
}