.establishment-services-assistance-container {

    padding: 20px;
  }
  

  .centre {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 30px; 
  }