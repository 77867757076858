.rowhome{
    display: flex;
    flex-direction: column;

}
.treatment-container {
    background-color: #f4f6fa;
}

.wardoana {
    align-items: start;
    display: flex;
    font-size: 30px;
    color: #333;
}

.wardoanatxt {
    align-items: start;
    font-size: 30px;
    color: #333;
}

.offers-section {
    padding: 20px;
    text-align: center;
}

.talib0 {    padding: 20px;

    background-color: #072C7B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    flex-wrap: wrap;
}
.talib14 {   
     padding: 20px;

}
.text-column {
    flex: 2;
    padding-right: 20px;
    color: #ffffff;
    text-align: right;
}

.image-column {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.talib2image {
    max-width: 100px;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.offers-section h3 {
    font-size: 1.6rem;
    color: #072C7B;
    margin-bottom: 30px;
}

/* Offer items grid */
.offer-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3-column grid */
    grid-gap: 20px;
    margin-top: 20px;
}

.offer-item {
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.offer-icon {
    width: 60px;
    margin-bottom: 10px;
}

.offer-item h4 {
    font-size: 1.2rem;
    color: #072C7B;
    margin-bottom: 10px;
}

.offer-item p {
    font-size: 0.9rem;
    color: #555;
}

.talib1 {
    font-size: 24px;
    font-weight: bold;
    text-align: justify;
    color: #ffffff;
    margin-bottom: 15px;
}

.talib2 {
    font-size: 18px;
    line-height: 1.6;
    text-align: justify;
    color: #ffffff;
    margin: 10px 0;
    padding: 0 20px;
}

@media (max-width: 768px) {

    .offer-items {
        grid-template-columns: repeat(2, 1fr);
    }

    .text-column {
        flex: 1;
        text-align: center;
        padding-right: 0;
        margin-bottom: 20px;
    }

    .image-column {
        flex: 1;
        margin-bottom: 20px;
    }

    .wardoanatxt {
        align-items: start;
        font-size: 20px;
        color: #333;
    }

    .talib1 {
        font-size: 20px;
        /* Adjust title size for smaller screens */
    }

    .talib2 {
        font-size: 16px;
        /* Adjust paragraph font size for smaller screens */
    }
}

@media (max-width: 480px) {

    .wardoanatxt {
        align-items: start;
        font-size: 20px;
        color: #333;
    }

    .offer-items {
        grid-template-columns: 1fr;
        /* Single-column grid for mobile */
    }

    .talib0 {
        flex-direction: column;
        /* Stack columns on mobile */
        text-align: center;
        /* Center text on mobile */
    }

    .talib1 {
        font-size: 18px;
        /* Further reduce title size for small devices */
    }

    .talib2 {
        font-size: 14px;
    }

    .offer-item {
        padding: 15px;
    }

    .offer-icon {
        width: 50px;
    }
}